<nav class="navbar navbar-expand p-0 bg-white shadow-sm mw-sm-1280p" role="navigation" *ngIf="parentElement && elements?.length">
  <div class="container-fluid px-3">
    <div class="collapse navbar-collapse px-md-3">
      <ul class="navbar-nav nav-pills nav flex-grow-1">
        <li *ngFor="let element of elements" class="nav-item">
          <a class="btn nav-link no-outline"
             [href]="element.externalPath ?? '#'"
             [target]="element.externalPathTarget ?? '_self'"
             [class.active]="element === selectedElement"
             (click)="onClick(element, $event)"
          >{{ element.title | translate }}
          </a>
        </li>
      </ul>
      <app-navigation-search-input
        *ngIf="showSearch()"
        (search)="onCloseThirdNavbar()"
      ></app-navigation-search-input>
    </div>
  </div>
</nav>
