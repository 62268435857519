import { ElementRef, Injectable } from '@angular/core';
import { arrowSize } from './model';

export enum POSITIONS {
  TOP = 'top',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM = 'bottom',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum TooltipContainerPositionClasses {
  TOP = 'tooltip--top',
  BOTTOM = 'tooltip--bottom',
  LEFT = 'tooltip--left',
  RIGHT = 'tooltip--right',
}

@Injectable({
  providedIn: 'root',
})
export class TooltipStylesGeneratorService {
  constructor() {}

  generate(position: POSITIONS, element: ElementRef, parentElement: ElementRef, bounds: HTMLElement) {
    const boundsRect = bounds ? bounds.getBoundingClientRect() : null;
    const parentRect = parentElement.nativeElement.getBoundingClientRect();
    const elementRect = element.nativeElement.getBoundingClientRect();

    if (Object.values(POSITIONS).includes(position)) {
      const styles = {
        ...this.verticalAlign(position, parentRect, elementRect),
        ...this.horizontalAlign(position, parentRect, elementRect, boundsRect),
      };
      const className = this.guess(position);
      return {
        styles,
        className,
      };
    } else {
      throw new Error(`Position ${position} is not available.`);
    }
  }

  private verticalAlign(position, parentRect, elementRect) {
    if ([
      POSITIONS.BOTTOM,
      POSITIONS.BOTTOM_LEFT,
      POSITIONS.BOTTOM_RIGHT
    ].includes(position)) {
      return {
        top: `${parentRect.top + arrowSize.HEIGHT / 2 + parentRect.height}px`,
      };
    } else if ([
      POSITIONS.LEFT,
      POSITIONS.RIGHT
    ].includes(position)) {
      return {
        top: `${parentRect.top + parentRect.height / 2 - elementRect.height / 2}px`,
      };
    }
    // In other way it will be TOP positioned tooltip
    return {
      top: `${parentRect.top - arrowSize.HEIGHT / 2 - elementRect.height}px`,
    };
  }

  private horizontalAlign(position, parentRect, elementRect, boundsRect?) {
    if (position === POSITIONS.TOP_LEFT || position === POSITIONS.BOTTOM_LEFT) {
      return {
        left: `${parentRect.left - elementRect.width * 0.75 + parentRect.width}px`,
      };
    }
    if (position === POSITIONS.TOP_RIGHT || position === POSITIONS.BOTTOM_RIGHT) {
      return {
        left: `${(boundsRect ? boundsRect.right : parentRect.right) - elementRect.width}px`,
      };
    } else if (position === POSITIONS.LEFT) {
      return {
        left: `${parentRect.left - elementRect.width}px`,
      };
    } else if (position === POSITIONS.RIGHT) {
      return {
        left: `${parentRect.right}px`,
      };
    }
    // In other way tooltip will be in the middle
    return {
      left: `${parentRect.left - elementRect.width / 2 + parentRect.width / 2}px`,
    };
  }

  private guess(position: string) {
    switch (position) {
      case POSITIONS.TOP:
      case POSITIONS.TOP_LEFT:
      case POSITIONS.TOP_RIGHT:
        return TooltipContainerPositionClasses.TOP;
      case POSITIONS.BOTTOM:
      case POSITIONS.BOTTOM_LEFT:
      case POSITIONS.BOTTOM_RIGHT:
        return TooltipContainerPositionClasses.BOTTOM;
      case POSITIONS.LEFT:
        return TooltipContainerPositionClasses.LEFT;
      case POSITIONS.RIGHT:
        return TooltipContainerPositionClasses.RIGHT;
      default:
        throw new Error(`Position ${position} is not available.`);
    }
  }
}
