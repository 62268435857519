import {Component, EventEmitter, forwardRef, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {OrderArticleMaterialInputService} from './order-article-material-input.service';
import {debounceTime, distinctUntilChanged, map, withLatestFrom} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {OrderArticleMaterialInterface} from './order-article-material-interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const ARTICLE_MATERIAL_INPUT_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OrderArticleMaterialInputComponent),
  multi: true,
};

@Component({
  selector: 'app-order-article-material-input',
  templateUrl: './order-article-material-input.component.html',
  styleUrls: ['./order-article-material-input.component.scss'],
  // Needed otherwise not possible to set style for typeahead dropdown
  encapsulation: ViewEncapsulation.None,
  providers: [
    ARTICLE_MATERIAL_INPUT_ACCESSOR
  ]
})
export class OrderArticleMaterialInputComponent implements ControlValueAccessor {
  private materials$: Observable<OrderArticleMaterialInterface[]> = this.orderArticleMaterialInputService.getMaterials();

  @Input() set value(value: string | undefined) {
    this.initSelectedObjects(value);
  };
  @Input() text?: string;
  @Input() name?: string;
  @Input() placeholder?: string;
  @Input() theme?: string;
  @Input() defaultImg? = '../../../assets/icons/icon-material-placeholder-small.svg';
  @Input() limit? = 15;
  @Input() set disabled(disabled: boolean | undefined) {
    if (disabled !== undefined) {
      this.isDisabled = disabled;
    }
  };
  @Input() listOnly = false;

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  isDisabled = false;
  selectedObjects: OrderArticleMaterialInterface[] = [];

  private onChangedCallback = (value: string) => {};
  onTouchedCallback = () => {};

  constructor(private orderArticleMaterialInputService: OrderArticleMaterialInputService) {}

  initSelectedObjects(value: string | null | undefined) {
    if (!value) {
      this.selectedObjects = [];
      return;
    }

    this.orderArticleMaterialInputService.parseMaterialsString(value).subscribe(res => {
      this.selectedObjects = res;
    })
  }

  search = (text: Observable<string>) =>
    text.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      withLatestFrom(this.materials$),
      map(([term, materials]) => {
        if (term.length < 1) {
          return [];
        }

        const filtered = materials
          .filter(
            v =>
              v.code?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
              v.extraCode?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
              (v.translation && v.translation.toLowerCase().indexOf(term.toLowerCase()) > -1)
          )
          .slice(0, this.limit);

        if (!filtered.length) {
          filtered.push({
            code: term,
            img: this.defaultImg,
            translation: term
          });
        }

        return filtered;
      })
    );

  itemSelected($event, input) {
    $event.preventDefault();
    if (this.selectedObjects.indexOf($event.item) === -1) {
      this.selectedObjects.push($event.item);
      this.setValue();
    }

    input.value = '';
  }

  setValue() {
    const valueString = this.selectedObjects.map(material => material.code).join(', ');
    this.onChange.emit(valueString);
    this.onChangedCallback(valueString);
  }

  removeSelectedItem($event, code: OrderArticleMaterialInterface) {
    $event.preventDefault();
    this.selectedObjects = this.selectedObjects.filter(obj => obj !== code);

    this.setValue();
  }

  writeValue(newValue: string | null): void {
    this.initSelectedObjects(newValue);
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouchedCallback = fn;
  }

  registerOnChange(fn: (value: string) => {}): void {
    this.onChangedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  formatMaterialRow(result: OrderArticleMaterialInterface) {
    return `${result.translation || result.code}${result.extraCode?.length ? ` (${result.extraCode})` : ''}`;
  }

  preventItemRemovalOnEnter(event: Event): void {
   event.preventDefault();
  }
}
