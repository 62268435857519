import { Component, ElementRef, Input, OnChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

export const SEARCHABLE_SELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SearchableSelectComponent),
  multi: true,
};

@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  providers: [SEARCHABLE_SELECT_VALUE_ACCESSOR,{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class SearchableSelectComponent implements ControlValueAccessor, OnChanges {
  isOpen = false;
  filterValue = "";
  filteredOptions = [];
  isDisabled = false;

  value: any;
  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() {}

  @ViewChild('filter') filterInput: ElementRef<HTMLInputElement>;
  @Input() options: any[] = [];
  @Input() placeholder: string = '-';
  @Input() class: string = 'd-inline-block';
  @Input() textField: string = 'name';
  @Input() valueField: string = 'value';
  @Input() disabledField: string = 'disabled';

  ngOnChanges(e) {
    this.filteredOptions = this.options;
    this.valueField = this.valueField;
    this.textField = this.textField;
  }

  openChangeHandler(event: boolean) {
    this.isOpen = event;

    // this will trigger focus on recently showed input
    setTimeout(() => {
      this.filterInput.nativeElement.focus();
    }, 0)
  }

  selectValue(item: any) {
    this.value = item;
    this.onChange(item);
    this.onTouch();
  }

  findOptions(event: any) {
    this.filteredOptions = this.options.filter((item) =>
    item[this.valueField].toLocaleLowerCase().indexOf(this.filterValue.toLocaleLowerCase()) !== -1 ||
    item[this.textField].toLocaleLowerCase().indexOf(this.filterValue.toLocaleLowerCase()) !== -1);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(item: any): void {
    this.value = item;
    this.onChange(item);
    this.onTouch();
  }
}
