<form [formGroup]="form">
  <app-generic-modal
    bodyClasses="bg-white p-3 d-flex flex-column"
    [cssClasses]="!updateMode ? 'd-flex flex-column vh-100' : 'd-flex flex-column'"
  >
    <ng-template appModalHeader>
      <h3 class="m-0">
        {{ (updateMode ? 'INQUIRIES.PROJECT_RESERVATIONS.PREVIEW.TITLE_MARK_AS_WON' : 'INQUIRIES.PROJECT_RESERVATIONS.NEW.TITLE') | translate }}
      </h3>
    </ng-template>

    <ng-template appModalBody>
      <div class="container-fluid container-lg d-flex flex-column gap-4 p-2">
        <div *ngIf="!updateMode" class="row">
          <div class="col">
            <app-alert data-testid="minimalAmountNote" variant="gray" [closeable]="false">
              {{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.MINIMAL_AMOUNT_ALERT' | translate }}
            </app-alert>
          </div>
        </div>
        <div *ngIf="updateMode" class="row">
          <div class="col">
            <label class="mb-0">{{ 'INQUIRIES.PROJECT_RESERVATIONS.OUTCOME.INSTRUCTIONS' | translate }}</label>
          </div>
        </div>
        <div *ngIf="updateMode" class="row">
          <div class="col">
            <label for="upcomingOrdersCount" class="form-label mb-3">
              {{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.UPCOMING_ORDERS_COUNT' | translate }} *
            </label>
            <input
              id="upcomingOrdersCount"
              name="upcomingOrdersCount"
              formControlName="upcomingOrdersCount"
              class="form-control"
              [placeholder]="'INQUIRIES.PROJECT_RESERVATIONS.NEW.UPCOMING_ORDERS_COUNT' | translate"
              [class.is-invalid]="form.controls.upcomingOrdersCount.touched && form.controls.upcomingOrdersCount.invalid"
              type="number"
              min="1"
            >
            <div class="invalid-feedback">
              {{ form.controls.upcomingOrdersCount.errors | translateValidationErrors }}
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!updateMode">
          <div class="col">
            <label for="title" class="form-label mb-3">{{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.PROJECT_NAME' | translate }} *</label>
            <input
              id="title"
              name="title"
              formControlName="title"
              class="form-control"
              [placeholder]="'INQUIRIES.PROJECT_RESERVATIONS.NEW.PROJECT_NAME' | translate"
              [class.is-invalid]="form.controls.title.touched && form.controls.title.invalid"
            >
            <div class="invalid-feedback">
              {{ form.controls.title.errors | translateValidationErrors }}
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!updateMode">
          <div class="col-6">
            <label for="country" class="form-label mb-3">{{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.COUNTRY' | translate }} *</label>
            <select
              id="country"
              class="form-control"
              formControlName="country"
              name="country"
              [class.disabled]="isDisabled"
              [class.is-invalid]="form.controls.country.touched && form.controls.country.invalid"
            >
              <option [ngValue]="null" disabled>{{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.COUNTRY' | translate }}</option>
              <option *ngFor="let item of countries" [ngValue]="item.id">{{ item.title }}</option>
            </select>
            <div class="invalid-feedback">
              {{ form.controls.country.errors | translateValidationErrors }}
            </div>
          </div>
          <div class="col-6">
            <label for="city" class="form-label mb-3">{{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.CITY' | translate }} *</label>
            <input
              id="city"
              name="city"
              formControlName="city"
              class="form-control"
              [placeholder]="'INQUIRIES.PROJECT_RESERVATIONS.NEW.CITY_PLACEHOLDER' | translate"
              [class.is-invalid]="form.controls.city.touched && form.controls.city.invalid"
            >
            <div class="invalid-feedback">
              {{ form.controls.city.errors | translateValidationErrors }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label for="value" class="form-label mb-3">{{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.PROJECT_VALUE' | translate }} *</label>
            <input
              type="number"
              id="value"
              name="value"
              formControlName="value"
              class="form-control"
              [placeholder]="'INQUIRIES.PROJECT_RESERVATIONS.NEW.PROJECT_VALUE_PLACEHOLDER' | translate"
              [class.is-invalid]="form.controls.value.touched && form.controls.value.invalid"
            >
            <div class="invalid-feedback">
              {{ form.controls.value.errors | translateValidationErrors }}
            </div>
          </div>
          <div class="col-6">
            <label for="workplaceCount" class="form-label mb-3">{{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.WORKPLACE_COUNT' | translate }}</label>
            <input
              type="number"
              id="workplaceCount"
              name="workplaceCount"
              formControlName="workplaceCount"
              class="form-control"
              [placeholder]="'INQUIRIES.PROJECT_RESERVATIONS.NEW.WORKPLACE_COUNT_PLACEHOLDER' | translate"
              step="1"
              min="1"
              [class.is-invalid]="form.controls.workplaceCount.touched && form.controls.workplaceCount.invalid"
            >
            <div class="invalid-feedback">
              {{ form.controls.workplaceCount.errors | translateValidationErrors }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label for="estimatedWinDate" class="form-label mb-3">
              {{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.ESTIMATED_PROJECT_WIN_DATE' | translate }}<span *ngIf="!updateMode"> *</span>
            </label>
            <app-date-input
              id="estimatedWinDate"
              formControlName="estimatedWinDate"
              name="estimatedWinDate"
              class="ps-2 order-date-input"
              appMinDateNow
              autocomplete="off"
              [class.is-invalid]="form.controls.estimatedWinDate.touched && form.controls.estimatedWinDate.invalid"
            >
              <span invalid-feedback></span>
            </app-date-input>
            <div class="invalid-feedback">
              {{ form.controls.estimatedWinDate.errors | translateValidationErrors }}
            </div>
          </div>
          <div class="col-6">
            <label for="executionDate" class="form-label mb-3">{{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.PROJECT_EXECUTION_DATE' | translate }} *</label>
            <app-date-input
              id="executionDate"
              formControlName="executionDate"
              name="executionDate"
              class="ps-2 order-date-input"
              appMinDateNow
              autocomplete="off"
              [class.is-invalid]="form.controls.executionDate.touched && form.controls.executionDate.invalid"
            >
              <span invalid-feedback></span>
            </app-date-input>
            <div class="invalid-feedback">
              {{ form.controls.executionDate.errors | translateValidationErrors }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="productSystemsFilter" class="form-label mb-3">{{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.PRODUCT_SYSTEMS' | translate }} *</label>

            <ng-template #rt let-r="result" let-t="term">
              <div class="d-flex align-items-center" [class.disabled]="r.disabled">
                <i class="icon ni-check" *ngIf="r.disabled"></i>
                <ngb-highlight highlightClass="fw-bold" [result]="r.name" [term]="t"></ngb-highlight>
              </div>
            </ng-template>

            <div class="position-relative">
              <input
                id="productSystemsFilter"
                *ngIf="!isDisabled"
                type="text"
                class="form-control"
                #productSystemSearchInput
                #instance="ngbTypeahead"
                [placeholder]="'INQUIRIES.PROJECT_RESERVATIONS.NEW.PRODUCT_SYSTEMS_PLACEHOLDER' | translate"
                [ngbTypeahead]="search"
                [editable]="false"
                [disabled]="isDisabled"
                [resultTemplate]="rt"
                [focusFirst]="true"
                [popupClass]="'product-systems-typeahead w-100'"
                [placement]="'bottom'"
                (selectItem)="onSystemSelected($event, productSystemSearchInput)"
                (blur)="onTouchedCallback(productSystemSearchInput)"
                [class.is-invalid]="form.controls.productSystems.touched && form.controls.productSystems.invalid && !selectedProductSystems.length"
              />

              <div class="system-selection pt-2" *ngIf="selectedProductSystems.length">
                <div
                  class="d-inline-flex align-items-center bg-gray-200 rounded-2py-1 px-2 me-2 mb-2 color-gray-900"
                  *ngFor="let system of selectedProductSystems"
                  [title]="system.name"
                >
                  <span class="text-truncate small">{{ system.name }}</span>
                  <button
                    type="button"
                    class="btn btn-default btn-xs color-gray d-inline-flex p-0 ms-2"
                    *ngIf="!isDisabled"
                    (click)="removeSelectedSystem(system)"
                  ><i class="icon ni-close"></i>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="invalid-feedback"
              [class.d-block]="form.controls.productSystems.touched && form.controls.productSystems.invalid && !selectedProductSystems.length"
            >
              {{ form.controls.productSystems.errors | translateValidationErrors }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="description" class="form-label mb-3">{{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.DESCRIPTION' | translate }}</label>
            <textarea rows="10" id="description" name="description" formControlName="description" class="form-control"
            [placeholder]="'INQUIRIES.PROJECT_RESERVATIONS.NEW.DESCRIPTION_PLACEHOLDER' | translate"></textarea>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template appModalFooter>
      <div
        class="d-inline-flex"
        [ngbTooltip]="'INQUIRIES.PROJECT_RESERVATIONS.NEW.TOOLTIP_FILL_IN_FORM' | translate"
        [disableTooltip]="!form.invalid"
      >
        <button type="button"
          class="btn btn-secondary btn-lg d-flex align-items-center justify-content-center h-100 no-outline"
          (click)="onDiscard()">
          {{ 'MODAL.BUTTON.CANCEL' | translate }}
        </button>
        <button type="submit"
          class="btn btn-primary btn-lg d-flex align-items-center justify-content-center h-100 no-outline"
          (click)="updateMode ? onUpdate() : onSubmit()" [disabled]="form.invalid || preventSubmit">
          {{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.SUBMIT' | translate }}
        </button>
      </div>
    </ng-template>
  </app-generic-modal>
</form>

<ng-template #projectReservationToast>
  <div class="d-flex">
    <i class="icon ni-check me-2"></i>
    <span class="flex flex-column gap-2 color-white fw-bold">
      {{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.RESERVATION_SUBMITTED' | translate }}
      <button class="btn btn-sm border d-flex py-01875r text-nowrap rounded-0 btn-outline-white" (click)="onToastButtonClick()">
        {{ 'INQUIRIES.PROJECT_RESERVATIONS.NEW.REVIEW_RESERVATION' | translate }}
      </button>
    </span>
  </div>
</ng-template>
