import { Component, EventEmitter, Input, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { UuidGenerator } from '../../core/util/uuid.generator';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html'
})
export class DateInputComponent implements ControlValueAccessor {
  private onChangeCallback: Function;
  private onTouchedCallback: Function;

  @Input() placeholder = 'yyyy-mm-dd';
  @Input() label?: string;
  @Input() name?: string;
  @Input() id = UuidGenerator.uuid();
  @Input() formGroupClass = 'row px-3 align-items-center';
  @Input() showInvalidBorder = false;
  @Input() minDate: NgbDateStruct | null = null;
  @Input() autocomplete?: string;

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Output() isFocused: EventEmitter<boolean> = new EventEmitter<boolean>();

  focused = false;
  disabled = false;

  public internalValue: NgbDateStruct = {
    year: null,
    month: null,
    day: null,
  };

  constructor(
    @Self() public ngControl: NgControl
  ) {
    ngControl.valueAccessor = this;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  formatDate(date: string): string {
    return moment(date, 'YYYY-M-D').format('YYYY-MM-DD');
  }

  onDateSelect(e) {
    const value = `${e.year}-${e.month < 10 ? '0' + e.month : e.month}-${e.day < 10 ? '0' + e.day : e.day}`;
    this.onChange(value);
    this.onTouchedCallback();
  }

  onKeyUp(e) {
    const { value } = e.target;

    if (value) {
      const cleaned = value.toString().replace(/[^0-9-]/g, '');

      this.onChange(cleaned);
    }
  }

  onFocus() {
    this.focused = true;
    this.isFocused.emit(true);
  }

  onBlur() {
    this.onTouchedCallback();
    this.focused = false;
    this.isFocused.emit(false);

    let value: string | null;

    if (this.internalValue) {
      const { year, month, day } = this.internalValue;
      value = this.formatDate(`${year}-${month}-${day}`);
    }

    this.onChange(value);
  }

  writeValue(obj: any): void {
    this.updateInternalValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onChange(value: string){
    const previousInternalValue = this.internalValue;
    this.updateInternalValue(value);
    // Validate date to make sure component doesn't emit invalid dates, for example: february 30
    if (!moment(value, 'YYYY-M-D').isValid()) {
      return;
    }

    // check if value actually didn't change, helps prevent useless change events on blur
    if (previousInternalValue.day === this.internalValue.day &&
      previousInternalValue.month === this.internalValue.month &&
      previousInternalValue.year === this.internalValue.year
    ) {
      return;
    }

    this.changed.emit(value);
    this.onChangeCallback(value);
  }

  onModelChange(event) {
    this.internalValue = event;
  }

  updateInternalValue(stringValue?: string): void {
    if (!stringValue || stringValue.length !== 10) {
      this.onChangeCallback?.call(null);
      return;
    }

    const chunks = stringValue.split('-');
    if (chunks.length === 3 && +chunks[0] > 0 && +chunks[1] > 0 && +chunks[2] > 0) {
      this.internalValue = {
        year: +chunks[0],
        month: +chunks[1],
        day: +chunks[2],
      };

      const value = this.formatDate(stringValue);
      this.onChangeCallback(value);
      this.changed.emit(value);
    }
  }
}
