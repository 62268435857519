<div [ngClass]="formGroupClass">
  <label *ngIf="label?.length" [for]="id" class="form-label w-50 pe-3 mb-0 text-end color-gray">{{ label }}</label>
  <div class="input-group px-0" [class.w-50]="label?.length">
    <input
      [id]="id"
      class="form-control"
      [placeholder]="placeholder"
      [name]="name"
      [ngModel]="internalValue"
      ngbDatepicker
      #d="ngbDatepicker"
      maxlength="10"
      (keyup)="onKeyUp($event)"
      (dateSelect)="onDateSelect($event)"
      (blur)="onBlur()"
      (focus)="onFocus()"
      [disabled]="disabled"
      [class.is-invalid]="showInvalidBorder && !ngControl.valid"
      container="body"
      [minDate]="minDate"
      [autocomplete]="autocomplete"
      (ngModelChange)="onModelChange($event)"
      data-testid="dateInput"
    />
    <button
      class="btn bg-gray-200 py-1 px-2 d-flex align-items-center calendar no-outline hover-color-black"
      [disabled]="disabled"
      (click)="d.toggle()"
      type="button"
      data-testid="datepickerToggleButton"
    >
      <i class="icon ni-calendar-today"></i>
    </button>
  </div>
  <div class="w-100" #feadbackContent>
    <ng-content select="[invalid-feedback]"></ng-content>
  </div>
  <div
    *ngIf="!feadbackContent.children.length"
    invalid-feedback
    class="invalid-feedback text-end"
    [ngClass]="{ 'd-block': ngControl?.errors }"
  >
    {{ ngControl?.errors | translateValidationErrors }}
  </div>
</div>
