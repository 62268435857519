import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionActions } from '../../../permissions.config';
import { PermissionsDecisionMakerServiceInterface } from '../../../permissions/decision-maker.service';
import { PERMISSIONS_DECISION_MAKER_SERVICE } from '../../../permissions/injection-tokens';
import { SaleMode, SalesModeLocalStorageKey } from './sale-mode.types';

@Injectable({
  providedIn: 'root'
})
export class ListModeSwitchService {
  private saleMode$: BehaviorSubject<SaleMode> = new BehaviorSubject<SaleMode>(SaleMode.ORDER);

  constructor(
    @Inject(PERMISSIONS_DECISION_MAKER_SERVICE) private permissionsDecisionMaker: PermissionsDecisionMakerServiceInterface
  ) {
    // check if we have salesMode stored in LocalStorage and use it
    const storedSalesMode = localStorage.getItem(SalesModeLocalStorageKey);
    if (storedSalesMode !== null) {
      this.setSaleMode(+storedSalesMode);
    }
  }

  static clearLocalStorage() {
    localStorage.removeItem(SalesModeLocalStorageKey);
  }

  static updateLocalStorage(saleMode: SaleMode) {
    localStorage.setItem(SalesModeLocalStorageKey, saleMode.toString());
  }

  saleModeAsObservable(): Observable<SaleMode> {
    return this.saleMode$.asObservable();
  }

  setSaleMode(mode: SaleMode) {
    const canViewSaleMode = this.permissionsDecisionMaker.shouldAllow([PermissionActions.CAN_VIEW_SALE_MODE]);
    const canViewOrderMode = this.permissionsDecisionMaker.shouldAllow([PermissionActions.CAN_VIEW_ORDER_MODE]);
    let saleMode = mode;

    // check if one or another is not possible for the current user and override what's passed
    // basically this should not happen as controls for switching the mode should not be visible
    // leaving this for compatibility reasons and in case of some UI errors
    if (canViewSaleMode && !canViewOrderMode) {
      saleMode = SaleMode.SALE;
    } else if (!canViewSaleMode && canViewOrderMode) {
      saleMode = SaleMode.ORDER;
    }

    this.saleMode$.next(saleMode);
  }

  getSaleMode(): number {
    return this.saleMode$.getValue();
  }

  isSaleMode(): boolean {
    return this.getSaleMode() === SaleMode.SALE;
  }
}
