import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';
import { saveAs as exportedSaveAs } from 'file-saver';
import { CacheableObservable, clearCache } from './cacheable-observable/cacheable-observable.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

// default file size limit is 128 MB, or 128 * 1024 * 1024 = 134217728 bytes
export enum FileSizeEnum {
  SIZE_1GB = 1073741824,
  SIZE_128MB = 134217728,
  SIZE_10MB = 10485760,
  SIZE_1MB = 1048576,
  SIZE_1KB = 1024,
}

export enum FileDropInvalidReasonEnum {
  GLOBAL_FILE_MIME_TYPE_ERROR = 'ER_100100#001',
  GLOBAL_FILE_MAX_SIZE_ERROR = 'ER_100100#002',
  PICTURE_MIME_TYPE_ERROR = 'ER_100101#001',
  PICTURE_MAX_SIZE_ERROR = 'ER_100101#002'
}

@Injectable()
export class ApiService {
  private cache = new Map();

  constructor(private http: HttpClient) {}

  download(path: string, fileName: string, params?, headers?) {
    headers = this.addNGSWBypassHeader(headers);
    
    // only modify path if it's relative. Paths starting with '//' are absolute
    if (!path.startsWith('//')) {
      path = environment.api + path;
    }

    return this.http
      .get(path, {
        params: params,
        responseType: 'blob',
        headers
      })
      .subscribe((data: any) => {
        const blob = new Blob([data], { type: data.type });
        exportedSaveAs(blob, fileName);
      });
  }

  downloadWithPost(path: string, body: any, fileName: string, params?, headers?) {
    headers = this.addNGSWBypassHeader(headers);
    
    // only modify path if it's relative. Paths starting with '//' are absolute
    if (!path.startsWith('//')) {
      path = environment.api + path;
    }
    
    return this.http
      .post(path, body, {
        params: params,
        responseType: 'blob',
        headers
      })
      .subscribe((data: any) => {
        const blob = new Blob([data], { type: data.type });
        exportedSaveAs(blob, fileName);
      });
  }

  get(path, params?, responseType?, headers?): CacheableObservable<any> {
    const httpResponseObservable = this.request('GET', path, params, null, headers, responseType);

    return CacheableObservable.create(httpResponseObservable, path);
  }

  post(path, body, params?, headers?): Observable<any> {
    return this.request('POST', path, params, body);
  }

  put(path, body, params?): Observable<any> {
    return this.request('PUT', path, params, body);
  }

  delete(path, body, params?): Observable<any> {
    return this.request('DELETE', path, params, body);
  }

  patch(path, body, params?): Observable<any> {
    return this.request('PATCH', path, params, body);
  }

  private addNGSWBypassHeader(headers?: HttpHeaders): HttpHeaders {
    if (!headers) {
      headers = new HttpHeaders({});
    }

    headers.append('ngsw-bypass', '');

    return headers;
  }

  private request(method, path, params?, body?, headers?: HttpHeaders, responseType?) {
    // only modify path if it's relative. Paths starting with '//' are absolute
    if (!path.startsWith('//')) {
      path = environment.api + path;
    }

    headers = this.addNGSWBypassHeader(headers);

    return this.http.request(method, path, { params, body, headers, responseType });
  }

  upload(path, body?: any, files?: File[] | File, sizeLimit?: number, name?: string): Observable<any> {
    const error = this.fileSizeLimitValidation(files, sizeLimit, name);
    if (error) {
      return throwError(() => error);
    }
    const formData = this.formatFormData(body, files, name);
    return this.post(path, formData);
  }

  patchWithFiles(path, body?, files?: File | File[], sizeLimit?: number, name?: string): Observable<any> {
    const error = this.fileSizeLimitValidation(files, sizeLimit, name);
    if (error) {
      return throwError(() => error);
    }
    const formData = this.formatFormData(body, files, name);
    return this.patch(path, formData);
  }

  buildFormData(body: { [key: string]: any }, skipNulls = true) {
    const formData: FormData = new FormData();
    for (const key in body) {
      if (!body.hasOwnProperty(key)) {
        continue;
      }

      const obj = body[key];
      if (obj === null && skipNulls) {
        continue;
      }

      if (typeof obj === 'string' || obj instanceof File) {
        formData.append(key, obj);
      } else {
        formData.append(key, JSON.stringify(obj));
      }
    }
    return formData;
  }

  formatFormData(body?: any, files?: File[] | File, name: string = 'files[]') {
    const formData: FormData = new FormData();
    if (files) {
      if (Array.isArray(files)) {
        (files as File[]).forEach(file => {
          formData.append(name, file);
        });
      } else {
        formData.append(name, files as File);
      }
    }
    for (const key in body) {
      if (typeof body[key] === 'string') {
        formData.append(key, body[key]);
      } else {
        formData.append(key, JSON.stringify(body[key]));
      }
    }
    return formData;
  }

  clearCache() {
    clearCache();
  }

  fileSizeLimitValidation(files: File[] | File, limitSize: number = FileSizeEnum.SIZE_1GB, name: string = 'files') {
    if (files) {
      // if files array
      if (Array.isArray(files)) {
        const filesArr = files as File[];

        if (filesArr.some(file => file.size > limitSize)) {
          // Copy of response that comes from backend then trying to upload too big file.
          return {
            error: {
              code: 400,
              message: 'Validation Failed',
              errors: { children: { [name]: { children: filesArr.map(file => file.size > limitSize ? {errors: ["ER_100100#002"]} : []) } } },
            },
          };
        }
        // if one file
      } else {
        if ((files as File).size > limitSize) {
          // Copy of response that comes from backend then trying to upload too big file.
          return { error: { code: 400, message: 'Validation Failed', errors: { children: { [name]: { errors: ['ER_100101#002'] } } } } };
        }
      }
    }
  }
}
