<app-loader [loading]="isLoading"></app-loader>
<div class="d-flex flex-column h-100 position-relative">
  <div class="messages-container flex-grow-1 d-flex flex-column gap-2 overflow-auto" id="messagesContainer" #messagesContainer>
    <div *ngIf="!messages.length && !isLoading" class="w-100 h-100 d-flex justify-content-center align-items-center">
      <div>
        {{ 'INQUIRIES.QUOTATIONS.NO_MESSAGES' | translate }}
      </div>
    </div>
    <div
      *ngFor="let message of messages; trackBy:trackByFn"
      class="message border-start border-4 d-flex"
      [ngClass]="user?.id === message.user?.id ? 'border-dark' : 'border-primary'"
    >
      <div class="d-flex flex-column me-3">
        <div class="d-flex align-items-center mb-2">
          <img 
            [src]="message.user?.profilePicture?.url ?? message.serviceDeskUserPhoto ?? '/assets/images/user/user@1,5x.svg'" 
            width="30"
            height="30"
            alt="avatar"
            class="object-fit-cover rounded-circle"
          />
          <p class="m-0 ms-2 color-gray-500">{{ message.user ? message.user.firstName + ' ' + message.user.lastName : message.serviceDeskUserName }}</p>
        </div>

        <div class="color-black message-text text-pre-wrap" [innerHTML]="message.text | linkify"></div>
        <app-files-list [files]="message.files"></app-files-list>
        <div class="color-gray-500">{{ message.dateTimestamp * 1000 | date:'YYYY-MM-dd H:mm' }}</div>
      </div>
    </div>
  </div>
  <app-message-field class="w-100 border-top"
    *ngIf="showMessageField"
    (submit)="onSent($event)"
    [acceptedFileTypes]="acceptedFileTypes"
    [multiple]="multiple"
    [theme]="messageFieldTheme"
    id="communicationMessageField"
  ></app-message-field>
</div>
