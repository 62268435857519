<form *ngIf="form" [formGroup]="form" class="form">
  <app-inline-field>
    <label for="deliveryAddressee" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.DELIVERY_ADDRESSEE' | translate }}</label>
    <input formControlName="deliveryAddressee"
      id="deliveryAddressee"
      type="text"
      class="form-control"
      [placeholder]="isMobile ? ('ADDRESS.FORM.DELIVERY_ADDRESSEE_EXPLANATION' | translate) : ''"
    >
    <ng-template *ngIf="getValidationErrors(form.get('deliveryAddressee')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
    <ng-template appInlineFieldInfoTemplate *ngIf="!isMobile">
      <div class="mt-2 small">{{ 'ADDRESS.FORM.DELIVERY_ADDRESSEE_EXPLANATION' | translate }}</div>
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="address" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.ADDRESS' | translate }}</label>
    <input formControlName="address"
      name="address"
      id="address"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('address')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="postCode" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.ZIP_CODE' | translate }}</label>
    <input formControlName="postCode"
      name="postCode"
      id="postCode"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('postCode')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="city" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.CITY' | translate }}</label>
    <input formControlName="city"
      name="city"
      id="city"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('city')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <ng-container *ngIf="!readOnly">
    <app-inline-field>
      <label for="country">{{ 'ADDRESS.FORM.COUNTRY' | translate }} *</label>
      <select formControlName="country"
        id="country"
        name="country"
        [compareWith]="compareCountry"
        class="form-control form-select"
      >
        <option [ngValue]="null" disabled>{{ 'ACTIONS.SELECT_OPTION' | translate }}</option>
        <option *ngFor="let country of countries" [ngValue]="country">{{ country.title || country.code }}</option>
      </select>
      <ng-template *ngIf="getValidationErrors(form.get('country')) as errors" appInlineFieldErrorsTemplate>
        {{ errors | translateValidationErrors }}
      </ng-template>
    </app-inline-field>
  </ng-container>
  <ng-container *ngIf="readOnly">
    <app-inline-field>
      <label for="countryDisabled" appInlineFieldLabel [hideRequired]="readOnly">{{ 'CLIENT.FORM.COUNTRY' | translate }}</label>
      <input formControlName="countryDisabled"
        name="countryDisabled"
        id="countryDisabled"
        class="form-control"
      >
    </app-inline-field>
  </ng-container>

  <app-inline-field>
    <label for="contactWorkingHours" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.CONTACT_WORKING_HOURS' | translate }}</label>
    <input formControlName="contactWorkingHours"
      name="contactWorkingHours"
      id="contactWorkingHours"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('contactWorkingHours')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <ng-container *ngIf="!readOnly && fields.deliveryRequirements?.visible">
    <div class="row">
      <div class="col">
        <div class="d-flex gap-2 mt-4">
          <h4 class="color-gray mb-1">{{ 'ORDERS.ADDITIONAL_DELIVERY_REQUIREMENTS.TITLE' | translate }}</h4>
          <div
            tooltipOpener
            [tooltipContent]="tooltipContent"
            additionalClasses="tooltip--black"
            tooltipPosition="top"
            tooltipArrowPosition="bottom"
          >
            <i class="icon ni-live-help"></i>
          </div>
        </div>
        <div class="form-h-spacer"></div>
      </div>
    </div>

    <app-inline-field>
      <label for="deliveryRequirements" appInlineFieldLabel [hideRequired]="readOnly" class="h-100">
        <div class="d-block pt-1 h-100">
          {{ 'ORDERS.ADDITIONAL_DELIVERY_REQUIREMENTS.TITLE' | translate }}
        </div>
      </label>
      <textarea formControlName="deliveryRequirements"
        name="deliveryRequirements"
        id="deliveryRequirements"
        class="form-control"
      ></textarea>
    </app-inline-field>

    <ng-container *ngTemplateOutlet="additionalDeliveryFields"></ng-container>
  </ng-container>

  <div class="row">
    <div class="col">
      <h4 class="color-gray mt-4">{{ 'ADDRESS.FORM.CONTACT_FOR_DELIVERY' | translate }}</h4>
      <div class="form-h-spacer"></div>
    </div>
  </div>

  <app-inline-field>
    <label for="contactPerson" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.CONTACT_PERSON' | translate }}</label>
    <input formControlName="contactPerson"
      name="contactPerson"
      id="contactPerson"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('contactPerson')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="email" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.EMAIL' | translate }}</label>
    <input formControlName="email"
      name="email"
      id="email"
      type="email"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('email')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <app-inline-field>
    <label for="phone" appInlineFieldLabel [hideRequired]="readOnly">{{ 'ADDRESS.FORM.PHONE_NUMBER' | translate }}</label>
    <input formControlName="phone"
      id="phone"
      name="phone"
      class="form-control"
    >
    <ng-template *ngIf="getValidationErrors(form.get('phone')) as errors" appInlineFieldErrorsTemplate>
      {{ errors | translateValidationErrors }}
    </ng-template>
  </app-inline-field>

  <ng-container *ngIf="readOnly && (model?.deliveryRequirements || model?.deliveryDate || model?.deliveryTimeFrom || model?.deliveryTimeTo)">
    <div class="row">
      <div class="col">
        <h4 class="color-gray mt-4">{{ 'ORDERS.ADDITIONAL_DELIVERY_REQUIREMENTS.TITLE' | translate }}</h4>
        <div class="form-h-spacer"></div>
      </div>
    </div>

    <div *ngIf="model?.deliveryRequirements" class="row">
      <div class="col text-gray">
        {{ model.deliveryRequirements }}
      </div>
    </div>
    <div *ngIf="model?.deliveryDate || model?.deliveryTimeFrom || model?.deliveryTimeTo" class="row pt-3">
      <div class="col d-flex gap-3">
        <div *ngIf="model?.deliveryDate" class="d-flex gap-1">
          <i class="icon ni-calendar-today"></i> {{ model?.deliveryDate }}
        </div>
        <div *ngIf="model?.deliveryTimeFrom && model?.deliveryTimeTo" class="d-flex gap-1">
          <i class="icon ni-clock"></i> {{ model?.deliveryTimeFrom }} - {{ model?.deliveryTimeTo }}
        </div>
      </div>
    </div>
  </ng-container>
</form>

<ng-template #tooltipContent>
  <div class="text-pre-wrap">
    {{ 'ORDERS.ADDITIONAL_DELIVERY_REQUIREMENTS.TOOLTIP' | translate }}
  </div>
</ng-template>
