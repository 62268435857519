<div class="mt-2 mb-1 d-flex flex-column gap-1" *ngIf="files.length > 0">
  <div class="files-preview d-flex flex-wrap gap-1">
    <div 
      *ngFor="let file of imageFiles; let i = index"
      class="file-item border border-1"
      [previewFile]="file"
    >
      <img 
        width="192"
        height="148"
        [src]="file.url"
        [alt]="file.name"
      />
    </div>
  </div>
  <div class="d-flex flex-wrap gap-1">
    <button 
      *ngFor="let file of otherFiles; let i = index"
      class="btn btn-outline-primary rounded d-flex gap-2 px-1 ps-sm-2 pe-sm-3 border border-1"
      type="button"
      [previewFile]="file"
    >
      <i class="icon ni-download"></i>
      <span>{{ file.name }}</span>
    </button>
  </div>
</div>
