import { Component, Input } from '@angular/core';
import { FileData } from '../../../core/models/file-data.model';

@Component({
  selector: 'app-preview-image-modal',
  templateUrl: './preview-image-modal.component.html',
  styleUrls: ['./preview-image-modal.component.scss']
})
export class PreviewImageModalComponent {
  @Input() image: FileData;
  
  constructor() { }
}
