import { Component, Input } from '@angular/core';
import { OrderArticleMaterialInterface } from '../order-article-material-input/order-article-material-interface';
import { NgClass, NgFor } from '@angular/common';
import { MaterialsListItemComponent } from './materials-list-item/materials-list-item.component';

@Component({
    selector: 'app-materials-list',
    templateUrl: './materials-list.component.html',
    imports: [NgClass, NgFor, MaterialsListItemComponent]
})
export class MaterialsListComponent {
  @Input() materials: OrderArticleMaterialInterface[];
  @Input() materialsOfOrderArticleNonRegularProduct = false;
  @Input() showMaterialCode = false;
  @Input() showMaterialTitle = true;
  @Input() showMaterialImage = true;
  @Input() materialListItemClass = 'd-flex';
  @Input() materialListClass = 'd-flex flex-wrap flex-column';
}
