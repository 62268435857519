import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormNativeElementDirective } from './directives/form-native-element/form-native-element.directive';
import { EncodeUriPipe } from './pipes/encode-uri.pipe';
import { I18nDatePipe } from './pipes/i18n-date/i18n-date.pipe';
import { PriceFormatPipe } from './pipes/price-format/price-format.pipe';
import { PriceListCurrencyPipe } from './pipes/price-list-currency/price-list-currency.pipe';
import { PricelistCurrencySymbolPipe } from './pipes/pricelist-currency-symbol/pricelist-currency-symbol.pipe';
import { ResizeImagePipe } from './pipes/resize-image/resize-image.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { SearchHighlightPipe } from './pipes/search-highlight/search-highlight.pipe';
import { TranslateValidationErrorsPipe } from './pipes/translate-validation-errors/translate-validation-errors.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { UncachePipe } from '../uncacheable-resource/uncache.pipe';
import { GenericModalComponent } from '../ui-elements/generic-modal/generic-modal.component';
import { ModalHeaderDirective } from '../ui-elements/generic-modal/directives/modal-header/modal-header.directive';
import { ModalBodyDirective } from '../ui-elements/generic-modal/directives/modal-body/modal-body.directive';
import { MobileModalBodyDirective } from '../ui-elements/generic-modal/directives/modal-body/mobile-modal-body.directive';
import { ModalFooterDirective } from '../ui-elements/generic-modal/directives/modal-footer/modal-footer.directive';
import { CloseButtonComponent } from '../ui-elements/close-button/close-button.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,

    GenericModalComponent,
    ModalHeaderDirective,
    ModalBodyDirective,
    MobileModalBodyDirective,
    ModalFooterDirective,
    CloseButtonComponent,

    PriceFormatPipe,
    SafeHtmlPipe,
    PriceListCurrencyPipe,
    SearchHighlightPipe,
    PricelistCurrencySymbolPipe,
    I18nDatePipe,
    TranslateValidationErrorsPipe,
    ResizeImagePipe,
    UncachePipe,
    TranslatePipe,

    FormNativeElementDirective,
  ],
  exports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,

    GenericModalComponent,
    ModalHeaderDirective,
    ModalBodyDirective,
    MobileModalBodyDirective,
    ModalFooterDirective,
    CloseButtonComponent,

    PriceFormatPipe,
    SafeHtmlPipe,
    PriceListCurrencyPipe,
    SearchHighlightPipe,
    PricelistCurrencySymbolPipe,
    I18nDatePipe,
    TranslateValidationErrorsPipe,
    ResizeImagePipe,
    UncachePipe,
    TranslatePipe,

    FormNativeElementDirective,
  ],
  providers: [EncodeUriPipe],
})
export class SharedModule {}
