<div 
  class="app-alert d-flex justify-content-between py-2 px-3" 
  [class]="getBgVariantClass(variant)" 
  data-testid="alertContainer"
>
  <div *ngIf="icon?.length" class="icon me-2 d-flex" (click)="onActionIconClick.emit($event)">
    <i class="icon my-auto" [class]="icon" data-testid="alertIcon"></i>
  </div>
  <div class="text w-100 mb-0 small d-flex pe-2">
    <div class="my-auto flex-grow-1">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="actions d-flex">
    <button
      *ngIf="hasFirstAction"
      class="btn btn-sm border d-flex py-01875r text-nowrap rounded-0"
      [class]="getBtnVariantClass(variant)"
      [class.px-01875r]="!actionOne"
      (click)="onActionOneClick.emit($event)"
      data-testid="actionOneButton"
    >
      <i
        *ngIf="actionOneIcon?.length"
        class="icon my-auto d-inline-flex font-smaller"
        [class]="actionOneIcon"
        [class.me-2]="actionOne"
        data-testid="actionOneIcon"
      ></i>
      {{ actionOne }}
    </button>
    <button
      *ngIf="hasSecondAction"
      class="btn btn-sm border d-flex py-01875r text-nowrap ms-2 rounded-0"
      [class]="getBtnVariantClass(variant)"
      [class.px-01875r]="!actionTwo"
      (click)="onActionTwoClick.emit($event)"
      data-testid="actionTwoButton"
    >
      <i
        *ngIf="actionTwoIcon?.length"
        class="icon my-auto d-inline-flex font-smaller"
        [class]="actionTwoIcon"
        [class.me-2]="actionTwo"
        data-testid="actionTwoIcon"
      ></i>
      {{ actionTwo }}
    </button>
    <ng-content select=".actions-content"></ng-content>
  </div>
  <div *ngIf="closeable" class="ms-2 close-icon d-flex" (click)="onActionCloseClick.emit($event)" data-testid="closeButton">
    <i class="icon ni-close mt-1"></i>
  </div>
</div>
