import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { PropertyImageSizes } from '../../../core/enums/property-image-sizes.enum';
import { MaterialsHelper } from '../../../core/util/materials-helper/materials.helper';
import { ImageSizeEnum } from '../../../shared/pipes/resize-image/resize-image.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { OrderArticleMaterialInterface } from '../../order-article-material-input/order-article-material-interface';

@Component({
    selector: 'app-materials-list-item',
    templateUrl: './materials-list-item.component.html',
    styleUrl: './materials-list-item.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [SharedModule]
})
export class MaterialsListItemComponent implements OnChanges {
  @Input() material: OrderArticleMaterialInterface;
  @Input() materialOfOrderArticleNonRegularProduct = false;
  @Input() showMaterialCode = false;
  @Input() showMaterialTitle = true;
  @Input() showMaterialImage = true;
  @Input() itemClass = 'd-flex';

  imageSizes = ImageSizeEnum;
  materialText: string;
  popoverImgSrc: string;
  MaterialsHelper = MaterialsHelper;

  constructor() { }

  ngOnChanges(): void {
    this.materialText = this.getMaterialDetailsText();
    this.popoverImgSrc = this.getUncachedImageBackground(PropertyImageSizes.LARGE);
  }

  getUncachedImageBackground(size?: PropertyImageSizes): string {
    const { img_thumbnails, img } = this.material;
    const value =
      (img_thumbnails &&
        ((size && img_thumbnails[size]) ||
          img_thumbnails[PropertyImageSizes.EXTRA_LARGE] ||
          img_thumbnails[PropertyImageSizes.LARGE] ||
          img_thumbnails[PropertyImageSizes.MEDIUM] ||
          img_thumbnails[PropertyImageSizes.SMALL])) ||
      img;

    // trying to update image URL to get larger file by replacing _32x32. to _240x240.
    // (full file name https://....cloudfront.net/files/SOME_CODE_32x32.jpg?version=***)
    if (value?.indexOf(`_${PropertyImageSizes.SMALL}.`) !== -1) {
      return value?.replace(`_${PropertyImageSizes.SMALL}.`, `_${PropertyImageSizes.EXTRA_LARGE}.`);
    }

    return value;
  }

  getMaterialDetailsText(): string {
    const { extraCode, extraPublicCode } = this.material;
    const extraCodeValue = extraPublicCode ?? extraCode;
    let extraCodeText = '';

    if (extraCodeValue && this.showMaterialCode) {
      extraCodeText = `(${extraCodeValue})`;
    }

    if (this.materialOfOrderArticleNonRegularProduct) {
      // check if material is not known (in such case its code is the same as its translation)
      if (this.material.code === this.material.translation) {
        return this.showMaterialTitle ? `${this.material.translation}` : '';
      }

      if (this.showMaterialCode) {
        return this.showMaterialTitle
          ? `${this.material.translation} ${extraCodeText}`
          : `${this.material.translation.split(' - ')[0]} ${extraCodeText}`;
      }

      return this.showMaterialTitle ? this.material.translation.split(' - ')[1] : '';
    }

    if(this.showMaterialCode) {
      if(!this.showMaterialTitle) {
        return `${this.material.code} ${extraCodeText}`;
      }

      const codeWithTranslation = Array.from(new Set([this.material.code, this.material.translation])).join(' - ');
      return `${codeWithTranslation} ${extraCodeText}`;
    }

    return this.showMaterialTitle ? `${this.material.translation} ${extraCodeText}` : '';
  }
}
