import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, debounceTime, distinctUntilChanged, filter, map, retry, skip, startWith, switchMap } from 'rxjs';
import { PriceRequestItemFileInterface, PriceRequestItemInterface } from '../../../../core/models/price-request-item.model';
import { FormHelper } from '../../../../core/util/form-helper/form.helper';
import * as CustomValidators from '../../../../shared/class/custom-validators';
import { SharedModule } from '../../../../shared/shared.module';
import { OkCancelModalComponent } from '../../../../ui-elements/generic-modal/ok-cancel-modal/ok-cancel-modal.component';
import { OrderArticleMaterialInputComponent } from '../../../../ui-elements/order-article-material-input/order-article-material-input.component';
import { ToastService } from '../../../../ui-elements/toast/toast.service';
import { PriceRequestFilesInputComponent } from '../../price-request-files-input/price-request-files-input.component';
import { PriceRequestItemsService } from '../../services/price-request-items.service';

@Component({
    selector: 'app-custom-price-request-item-form-modal',
    templateUrl: './custom-price-request-item-form-modal.component.html',
    styleUrls: ['./custom-price-request-item-form-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [SharedModule, OrderArticleMaterialInputComponent, PriceRequestFilesInputComponent]
})
export class CustomPriceRequestItemFormModalComponent implements OnInit, OnDestroy {
  @Input() priceRequestId: number;
  @Input() priceRequestItemId: number;
  @Input() editMode: boolean = true;
  @Output() saved = new EventEmitter<void>();
  @Output() discarded = new EventEmitter<void>();
  
  @HostListener('window:beforeunload', ['$event']) beforeUnload(e: BeforeUnloadEvent) {
    if (this.form.dirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  }
  
  private subscription = new Subscription();
  form: FormGroup = null;
  isUploadingFiles = false;
  isSubmitting = false;
  isDraft = false;
  atLeastOneFormControlHasValue = false;

  constructor(
    protected priceRequestItemsService: PriceRequestItemsService,
    protected activeModal: NgbActiveModal,
    protected translate: TranslateService,
    protected toastService: ToastService,  
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    if (!this.form) {
      this.constructForm();
    }

    this.atLeastOneFormControlHasValue = FormHelper.formHasAtLeastOneControlWithValue(this.form);

    this.subscription.add(
      this.form.valueChanges.subscribe((formValue: PriceRequestItemInterface) => {
        this.atLeastOneFormControlHasValue = FormHelper.formHasAtLeastOneControlWithValue(this.form);
        this.isUploadingFiles = !!formValue.customMadePriceRequestItemFiles.find(({ inProgress }) => inProgress);
      })
    );

    this.subscription.add(
      this.form.valueChanges
        .pipe(
          debounceTime(2000),
          startWith(this.form.getRawValue()),
          distinctUntilChanged((previous, current) => {
            return JSON.stringify(previous) === JSON.stringify(current);
          }),
          skip(1),
          filter(() => {
            // autosaves only if item is not being saved
            return !this.isSubmitting;
          }),
          switchMap((formValue) => {
            const itemCompleted = this.form.valid;
            return this.priceRequestItemsService
              .update(this.priceRequestId, this.priceRequestItemId, formValue, this.form.valid)
              .pipe(map(() => itemCompleted));
          }),
          retry()
        )
        .subscribe({
          next: (itemCompleted) => {
            let suffix = 'SUCCESSFULLY_SAVED_DRAFT';

            if (itemCompleted) {
              this.isDraft = false;
              suffix = 'CUSTOM_MADE_ITEM_SAVED';
            }

            this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`).subscribe((translation: string) => {
              this.toastService.success(translation);
            });
          },
          error: () => {
            const suffix = this.isDraft ? 'FAILED_TO_SAVE_DRAFT' : 'FAILED_TO_SAVE_ITEM';
            this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`).subscribe((translation: string) => {
              this.toastService.danger(translation);
            });
          },
        })
    );
  }

  constructForm(fields?: PriceRequestItemInterface): void {
    this.isDraft = !fields?.isCompleted;

    this.form = new FormGroup({
      title: new FormControl<string>(fields?.title ?? '', [Validators.required, Validators.maxLength(255)]),
      quantity: new FormControl<number>(fields?.quantity, [Validators.required, Validators.min(1), CustomValidators.numbers]),
      description: new FormControl<string>(fields?.description ?? '', Validators.required),
      dimensions: new FormControl<string>(fields?.dimensions ?? '', Validators.maxLength(255)),
      finishes: new FormControl<String>(fields?.finishes, [Validators.required, CustomValidators.notEmpty('finishes')]),
      customMadePriceRequestItemFiles: new FormControl<PriceRequestItemFileInterface[]>(fields?.customMadePriceRequestItemFiles ?? []),
    });
  }

  onSubmit(): void {
    this.form.updateValueAndValidity();

    this.isSubmitting = true;

    this.subscription.add(
      this.priceRequestItemsService.update(
        this.priceRequestId, 
        this.priceRequestItemId, 
        this.form.getRawValue(), 
        this.form.valid
      ).subscribe({
        next: (response) => {
          let suffix;
  
          if (this.form.valid) {
            suffix = 'CUSTOM_MADE_ITEM_SAVED';
          } else {
            suffix = 'SUCCESSFULLY_SAVED_DRAFT';
          }

          this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`).subscribe((translation: string) => {
            this.toastService.success(translation);
          });
          
          this.saved.emit();
          this.activeModal.dismiss();
        },
        error: (error) => {
          this.isSubmitting = false;
        }
      })
    );
  }

  onClose(): void {
    if (this.isSubmitting || this.isUploadingFiles) {
      return;
    }

    if (this.editMode) {
      this.priceRequestItemsService
        .update(this.priceRequestId, this.priceRequestItemId, this.form.value, this.form.valid)
        .pipe(
          switchMap(() => {
            const suffix = this.form.valid ? 'CUSTOM_MADE_ITEM_SAVED' : 'SUCCESSFULLY_SAVED_DRAFT';
            return this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`);
          })
        )
        .subscribe((translation: string) => {
          this.toastService.success(translation);
          this.saved.emit();
          this.activeModal.dismiss();
        });

      return;
    }

    this.translate
      .get([
        'ACTIONS.SAVE',
        'ACTIONS.DISCARD',
        'INQUIRIES.PRICE_REQUESTS.MODALS.SAVE_OR_DISCARD.HEADER',
        'INQUIRIES.PRICE_REQUESTS.MODALS.SAVE_OR_DISCARD.BODY',
      ])
      .subscribe((translations: { [key: string]: string }) => {
        const [save, discard, headerText, bodyText] = Object.values(translations);

        const modalRef = this.modalService.open(OkCancelModalComponent, {
          size: 'lg',
          centered: true,
        });

        const componentInstance = modalRef.componentInstance as OkCancelModalComponent;
        componentInstance.closeable = true;
        componentInstance.buttonTexts = [discard, save];
        componentInstance.buttonClasses = 'w-10r';

        componentInstance.bodyContent = bodyText;
        componentInstance.headerContent = headerText;

        componentInstance.cancel
          .pipe(
            switchMap(() => this.priceRequestItemsService.delete(this.priceRequestId, this.priceRequestItemId)),
            switchMap(() => {
              const suffix = this.form.valid ? 'DISCARDED' : 'DRAFT_DISCARDED';
              return this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`);
            })
          )
          .subscribe((translation: string) => {
            this.toastService.success(translation);
            this.discarded.emit();
            this.activeModal.dismiss();
          });

        componentInstance.ok
          .pipe(
            switchMap(() =>
              this.priceRequestItemsService.update(this.priceRequestId, this.priceRequestItemId, this.form.value, this.form.valid)
            ),
            switchMap(() => {
              let suffix = this.form.valid ? 'CUSTOM_MADE_ITEM_SAVED' : 'SUCCESSFULLY_SAVED_DRAFT';
              return this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`);
            })
          )
          .subscribe((translation: string) => {
            this.toastService.success(translation);
            this.saved.emit();
            this.activeModal.dismiss();
          });
      });
  }


  onDiscard(): void {
    if(this.isSubmitting || this.isUploadingFiles) {
      return;
    }

    const suffix = this.isDraft ? 'DRAFT_DISCARDED' : 'DISCARDED';

    this.subscription.add(
      this.translate.get(`INQUIRIES.PRICE_REQUESTS.NEW_ITEM.${suffix}`).subscribe((translation: string) => {
        this.priceRequestItemsService.delete(this.priceRequestId, this.priceRequestItemId).subscribe(() => {
          this.toastService.success(translation);
          this.discarded.emit();
          this.activeModal.dismiss();
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
