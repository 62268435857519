<div [ngClass]="materialListClass">
  <app-materials-list-item
    *ngFor="let material of materials"
    [material]="material"
    [materialOfOrderArticleNonRegularProduct]="materialsOfOrderArticleNonRegularProduct"
    [showMaterialCode]="showMaterialCode"
    [showMaterialTitle]="showMaterialTitle"
    [showMaterialImage]="showMaterialImage"
    [itemClass]="materialListItemClass"
  ></app-materials-list-item>
</div>
