import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewFileDirective } from './preview-file.directive';
import { PreviewImageModalComponent } from './preview-image-modal/preview-image-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { GenericModalModule } from '../generic-modal/generic-modal.module';
import { UncacheableResourceModule } from '../../uncacheable-resource/uncacheable-resource.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PreviewFileDirective,
    PreviewImageModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    GenericModalModule,
    UncacheableResourceModule,
    TranslateModule
  ],
  exports: [
    PreviewFileDirective
  ]
})
export class PreviewFileModule { }
