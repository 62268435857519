import { UserInterface } from './../core/models/user.model';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {
  OrderCreateInterface,
  OrderFilterInterface,
  OrderImportStateInterface,
  OrderInterface,
  OrderListRowInterface,
  OrderMigrateStateInterface,
  OrdersFilterBySOResultInterface,
  OrdersListResponse,
  OrderUpdateInterface,
} from '../core/models/order.model';
import { CacheableObservable } from '../cacheable-observable/cacheable-observable.model';
import { OrderCompactType } from '../core/enums/order-compact-type.enum';
import { FilterableListServiceInterface } from '../filterable-list/filterable-list-service.interface';
import { catchError, map } from 'rxjs/operators';
import { SaleMode } from '../shared/components/list-mode-switch/sale-mode.types';
import { CompactOrder } from './additional-order-details/confirm-details/compact-order.model';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { FullOrderArticleInterface } from '../core/models/full-order-article.model';
import { SortInterface } from '../core/models/sort.model';
import { SortFactory } from '../core/factory/sort.factory';
import { ListModeSwitchService } from '../shared/components/list-mode-switch/list-mode-switch.service';
import { UserRole } from '../core/enums/user-role.enum';
import { OrderRoutePath } from '../core/enums/route-types.enum';
import { OrderState } from "../core/enums/order.state.enum";
import { AxClientCode, AxClientCodesRequestParams } from '../core/models/ax-client-codes.model';
import { ProjectReservationCodeSuggestionInterface } from '../core/models/project-reservation.model';
import { PriceRequestInterface } from '../core/models/price-request.model';
import { PriceRequestItemInterface } from '../core/models/price-request-item.model';
import { environment } from '../../environments/environment';

export interface SuccessResponseInterface {
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService implements FilterableListServiceInterface {
  private reloadingArticles$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private reloadUpdatedOrder$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private reloadingOrder$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public sendingToAxInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public importInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public refreshOrderImportStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private reloadedArticles$: BehaviorSubject<FullOrderArticleInterface[]> = new BehaviorSubject([]);
  private reloadedOrder$: BehaviorSubject<OrderInterface> = new BehaviorSubject(null);
  private articleFullCodesInOrder$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  constructor(private api: ApiService, private listModeSwitchService: ListModeSwitchService) {}

  lastOrderData: OrderInterface;

  filterAll(filter: OrderFilterInterface, sort?: SortInterface): CacheableObservable<OrdersListResponse> {
    let params = {};
    const { states, ...rest } = filter;

    if (states && states.length > 0) {
      params = { 'states[]': states };
    }

    if (sort) {
      params = { ...params, sort: SortFactory.getString(sort) };
    }

    params = {'sale-mode': this.listModeSwitchService.getSaleMode(), ...rest, ...params };
    return this.api.get('orders/search', params) as CacheableObservable<OrdersListResponse>;
  }

  filterBySO(filter: string) {
    return this.api.get(`orders/search-by-ax-order-num/${filter}`).noCache().pipe(
      map((response) => response.data),
      catchError(() => {
        return of([]);
      })
    ) as CacheableObservable<OrdersFilterBySOResultInterface[]>;
  }

  getOne(id: number): CacheableObservable<OrderInterface> {
    this.startOrderReloading();
    const saleMode = this.listModeSwitchService.getSaleMode();
    return this.api.get(`orders/${id}?sale-mode=${saleMode}`).pipe(
      map(({ data }) => {
        if (data.axDispatchDateTimestamp) {
          data.axDispatchDateTimestamp = data.axDispatchDateTimestamp * 1000;
        }

        this.endOrderReloading(data);
        this.lastOrderData = data;
        this.reloadUpdatedOrder$.next(false);

        return data;
      })
    ) as CacheableObservable<OrderInterface>;
  }

  /**
   *
   * @param id
   * @param saleMode
   * @deprecated saleMode param should not be used anymore
   */
  getArticles(
    id: number,
    saleMode?: SaleMode,
    checkArticleMigrationVersions: boolean = true
  ): CacheableObservable<FullOrderArticleInterface[]> {
    this.startReloading();
    saleMode = saleMode || this.listModeSwitchService.getSaleMode();
    const saleModeParam = saleMode ? { 'sale-mode': saleMode } : {};
    return this.api.get(`orders/${id}/articles`, saleModeParam).pipe(
      map(({ data }) => {
        // here we need to detect if migration exists and if migration version matches the current version
        // if not - that means migration will be probably re run again so we need to reload entire order to show migrating status for user
        if (
          checkArticleMigrationVersions &&
          this.lastOrderData &&
          [OrderState.DRAFT, OrderState.WAITING].includes(this.lastOrderData.state) &&
          data.some(article =>
            article.orderArticle.orderArticleMigration?.version?.id &&
            article.orderArticle.orderArticleMigration.version.id !== this.lastOrderData.version.id &&
            article.orderArticle.versionOutdated
          )
        ) {
          this.reloadUpdatedOrder$.next(true);
        }

        this.endReloading(data);
        return data;
      })
    ) as CacheableObservable<FullOrderArticleInterface[]>;
  }

  getOrderClients(state: OrderState, dateFrom: string|null = null, dateUntil: string|null = null): Observable<AxClientCode[]> {
    let params: AxClientCodesRequestParams = {
      state: OrderState.AX_CONFIRMED
    };

    if (dateFrom) {
      params.from = dateFrom;
    }

    if (dateUntil) {
      params.until = dateUntil;
    }

    params.state = state;

    return this.api.get(`orders/ax-client-codes`, params).noCache();
  }

  restore(id: number) {
    return this.update(id, {
      state: OrderState.DRAFT,
    });
  }

  create(order?: OrderCreateInterface): Observable<OrderInterface> {
    return this.api.post('orders/', order).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }

  update(id: number, order?: OrderUpdateInterface): Observable<OrderInterface> {
    return this.api.patch(`orders/${id}`, order).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }

  getCompactOrder(id: number): CacheableObservable<CompactOrder> {
    return this.api.get(`orders/compact/${id}`).pipe(
      map(({ data }) => {
        return data;
      })
    ) as CacheableObservable<CompactOrder>;
  }

  toAxapta(id: number, compact: OrderCompactType = OrderCompactType.NORMAL): Observable<OrderInterface> {
    return this.api.post(`orders/send-ax/${id}/${compact}`, {}).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }

  import(id: number, file) {
    this.importInProgress$.next(true);
    const fd = new FormData();
    fd.append('file', file);

    return this.api.post(`orders/${id}/import/`, fd).pipe(map(({ data }) => data));
  }

  retryImport(id: number): CacheableObservable<string> {
    return this.api.get(`orders/${id}/import-retry/`).pipe(map(({ data }) => data)) as CacheableObservable<string>;
  }

  continueImport(id: number): Observable<string> {
    return this.api.post(`orders/${id}/import-continue/`, {}).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }

  retryMigration(id: number): CacheableObservable<string> {
    return this.api.get(`orders/${id}/migration-retry`).pipe(map(({ data }) => data)) as CacheableObservable<string>;
  }

  getImportStatus(id: number): CacheableObservable<OrderImportStateInterface> {
    return this.api.get(`orders/${id}/import-status`).pipe(map(({ data }) => data)) as CacheableObservable<OrderImportStateInterface>;
  }

  getMigrationStatus(id: number): CacheableObservable<OrderMigrateStateInterface> {
    return this.api.get(`orders/${id}/migration-status`).pipe(map(({ data }) => data)) as CacheableObservable<OrderMigrateStateInterface>;
  }

  filterOrders(orders: OrderInterface[], filter: OrderState[]): OrderInterface[] {
    if (!filter) {
      return orders;
    }
    return orders.filter((order: OrderInterface) => {
      return filter.includes(order.state);
    });
  }

  checkOrderReview(id: number): CacheableObservable<any> {
    return this.api.get(`orders/review/${id}`).pipe(map(({ data }) => data)) as CacheableObservable<any>;
  }

  reloadingArticlesAsObservable(): Observable<boolean> {
    return this.reloadingArticles$.asObservable();
  }

  startReloading() {
    this.reloadingArticles$.next(true);
  }

  reloadedArticlesAsObservable(): Observable<FullOrderArticleInterface[]> {
    return this.reloadedArticles$.asObservable();
  }

  endReloading(articles: FullOrderArticleInterface[]) {
    this.reloadingArticles$.next(false);
    this.reloadedArticles$.next(articles);

    const fullCodes = articles.map(article => article.orderArticle.fullCode);
    this.articleFullCodesInOrder$.next(fullCodes);
  }

  articleFullCodesInOrderAsObservable(): Observable<string[]> {
    return this.articleFullCodesInOrder$.asObservable();
  }

  importInProgressAsObservable(): Observable<boolean> {
    return this.importInProgress$.asObservable();
  }

  sendingToAxInProgressAsObservable(): Observable<boolean> {
    return this.sendingToAxInProgress$.asObservable();
  }

  startOrderReloading() {
    this.reloadingOrder$.next(true);
  }

  endOrderReloading(order: OrderInterface) {
    this.reloadingArticles$.next(false);
    this.reloadedOrder$.next(order);
  }

  reloadedOrderAsObservable(): Observable<OrderInterface> {
    return this.reloadedOrder$.asObservable();
  }

  reloadUpdatedOrderAsObservable(): Observable<boolean> {
    return this.reloadUpdatedOrder$.asObservable();
  }

  refreshOrderImportStatusAsObservable(): Observable<boolean> {
    return this.refreshOrderImportStatus$.asObservable();
  }

  exportToPdf(order: number, fileName: string) {
    return this.api.download(`orders/${order}/generate`, `${fileName}.pdf`);
  }

  getOrderPathByUserAndState(user: UserInterface, order: OrderInterface | OrderListRowInterface) {
    let result = [];
    switch (user.role.name) {
      case UserRole.ROLE_DEALER:
        switch (order.state) {
          case OrderState.DRAFT:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.OFFERS}`, order.id];
            break;
          case OrderState.IN_AX:
          case OrderState.WAITING:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.PENDING}`, order.id];
            break;
          case OrderState.AX_CONFIRMED:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.CONFIRMED}`, order.id];
            break;
          case OrderState.AX_LOADED:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.LOADED}`, order.id];
            break;
          case OrderState.AX_ARCHIVED:
          case OrderState.AX_ARCHIVE_CONFIRMED:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.ARCHIVE}`, order.id];
            break;
          case OrderState.DELETED:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.TRASH}`, order.id];
            break;
        }
        break;
      case UserRole.ROLE_PM_NARBUTAS:
        switch (order.state) {
          case OrderState.DRAFT:
          case OrderState.WAITING:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.ORDERS}`, order.id];
            break;
          default:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.CONFIRMED}`, order.id];
        }
        break;
      case UserRole.ROLE_PM:
      case UserRole.ROLE_PM_RU:
        switch (order.state) {
          case OrderState.WAITING:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.WAITING}`, order.id];
            break;
          case OrderState.IN_AX:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.PENDING}`, order.id];
            break;
          case OrderState.AX_CONFIRMED:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.CONFIRMED}`, order.id];
            break;
          case OrderState.AX_LOADED:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.LOADED}`, order.id];
            break;
          case OrderState.AX_ARCHIVED:
          case OrderState.AX_ARCHIVE_CONFIRMED:
            result = [`/${OrderRoutePath.ROOT}/${OrderRoutePath.ARCHIVE}`, order.id];
            break;
        }
        break;
    }

    return result;
  }

  getProjectReservationSuggestions(orderId: number): Observable<ProjectReservationCodeSuggestionInterface[]> {
    return this.api.get(`orders/${orderId}/project-reservation-code-suggestions`)?.noCache().pipe(
      map(({ data }) => data)
    );
  }

  addPriceRequestItems(orderId: OrderInterface['id'], itemIds: PriceRequestInterface['id'][], pageBreak: number | undefined): Observable<any> {
    let payload;

    if (pageBreak) {
      payload = { customMadePriceRequestItems: itemIds, pageBreak };
    } else {
      payload = { customMadePriceRequestItems: itemIds, pageBreak };
    }

    return this.api.post(`orders/${orderId}/add-custom-made-price-request-items`, payload)
  }

  getPriceRequestItemsNeedingClarification(orderId: OrderInterface['id']): Observable<PriceRequestItemInterface[]> {
    return this.api
      .get(`orders/${orderId}/custom-made-price-request-items-to-clarify`)
      .noCache()
      .pipe(map(({ data }) => data));
  }

  exportToObx(orderId: number, orderArticleIds: number[], textPageBreakIds: number[], realPageBreakIds: number[], groupPageBreakIds: number[]): Subscription {
    return this.api
      .downloadWithPost(
        environment.gofigure + `/export-obx`,
        { orderId, orderArticleIds, textPageBreakIds, realPageBreakIds, groupPageBreakIds },
        `order-${orderId}.obx`,
      );
  }

  resolveAllModifiedItems(orderId: OrderInterface['id']): Observable<SuccessResponseInterface> {
    return this.api.post(`orders/${orderId}/migration-resolve-all`, {});
  }
}
