<div
  class="bg-gray-200 group-row"
  [ngClass]="{
    selected: selected,
    'group-row--open border-bottom': row.isOpen,
    'hover-bg-white deep-hover-color-gray-400': !limitedView
  }"
  (click)="!limitedView ? onToggleGroup() : ''"
  (mouseenter)="!limitedView ? dragging && onMouseEnter() : ''"
  (mouseleave)="!limitedView ? onMouseLeave() : ''"
>
  <p *ngIf="limitedView; else nonLimitedView" class="m-0 p-2 color-gray-600 small">{{ row.title }}</p>
  <ng-template #nonLimitedView>
    <button
      class="btn btn-link d-flex color-gray-200 deep-hover drag-handle m-0 px-1 py-3"
      appDragHandle
      appClickStopPropagation
      [class.invisible]="isFilterEnabled"
    >
      <i class="icon ni-reorder"></i>
    </button>

    <div class="py-3 d-flex first-column">
      <app-check-mark
        [name]="row.id"
        [value]="selected"
        (changed)="onGroupSelect($event)"
        appClickStopPropagation
        cssClasses="m-0"
      ></app-check-mark>
    </div>
    <i class="icon color-black" [ngClass]="{ 'ni-expand-less': row.isOpen, 'ni-expand-more': !row.isOpen }"></i>
    <i *ngIf="shouldDisplayWarningIcon()" class="icon ni-warning-circle color-danger ms-2"></i>
    <div class="group-row__text auto-grow">
      <form [formGroup]="form" class="w-50 flex-grow-1">
        <input
          type="text"
          class="title-edit edit-inline form-control py-0125r border-1 px-2 color-black fw-bold"
          formControlName="title"
          #input
          [placeholder]="'ORDER_PRODUCTS_LIST.EXTRA_ITEMS.GROUP' | translate"
          (keyup.enter)="onEnterPress($event)"
          (keydown.esc)="onUndoChanges()"
          (blur)="onBlur($event)"
          AutoSizeInput
          appClickStopPropagation
        />
      </form>
    </div>
    <div class="w-50 d-flex align-items-center justify-content-end">
      <span class="mx-auto group-row__items-count small">
        ({{ row.productCount | i18nPlural: singularPluralMap | translate: { count: row.productCount } }})
      </span>
      <div
        class="py-1 pe-3 d-flex align-items-center group-row__last-column"
        [appAddClassesIfCustomDiscountApplied]="['custom-discount--applied']"
        [style.width.rem]="
          columns.actions.width +
          columns.price.width +
          columns.totalPrice.width -
          0.8125 +
          (isNetPriceFieldVisible && columns.netPrice.visible ? columns.netPrice.width : 0)
        "
        [class.active-mode--sale]="selectedSaleMode === saleMode.SALE"
      >
        <span class="py-2 pe-3 ps-0 group-row__total-price text-end color-gray" [style.width.rem]="columns.totalPrice.width">
          {{ row.childrenTotalPrice | priceFormat: { digitsInfo: '1.2-2' } }}
        </span>
        <div class="d-flex justify-content-center ms-1">
          <button
            class="btn p-1 btn-primary btn--action d-flex"
            appClickStopPropagation
            *ngIf="form.controls['title'].enabled"
            (click)="onEnterPress($event)"
          >
            <i class="icon ni-check"></i>
          </button>

          <ng-container *ngIf="form.controls['title'].disabled">
            <ng-container *ngTemplateOutlet="moreAction"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #moreAction>
  <div class="action-dropdown h-100" ngbDropdown #activeDropdown="ngbDropdown" [placement]="['bottom-end', 'top-end']">
    <button
      class="bg-gray-200 btn btn-link color-gray d-flex dropdown-toggle hover-color-black no-after p-1 my-2 no-outline"
      ngbDropdownToggle
      appClickStopPropagation
    >
      <i class="icon ni-more-horiz"></i>
    </button>

    <div ngbDropdownMenu class="p-0 m-0 dropdown-menu shadow border-0">
      <button
        class="dropdown-item btn btn-secondary d-flex"
        appClickStopPropagation
        (click)="enableEdit(); activeDropdown.close()"
      >
        <i class="icon ni-edit-text me-3"></i>
        <span>
          {{ 'ORDER_PRODUCTS_LIST.EXTRA_ITEMS.ACTIONS.EDIT' | translate }}
        </span>
      </button>
    </div>
  </div>
</ng-template>
