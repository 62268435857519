import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ucs2decode } from 'punycode/punycode.es6';
import * as moment from 'moment';

export function equal(value1: string, value2: string) {
  // check if two values are equal
  return (form: AbstractControl) => {
    if (form.get(value1).value !== form.get(value2).value) {
      form.get(value2).setErrors({ notEqual: true });
      return { notEqual: true };
    }
  };
}

export function match(value1: string, value2: string) {
  return (form: AbstractControl) => {
    if (form.get(value1).value === null || form.get(value1).value === '') {
      return null;
    }

    const validation: boolean = form.get(value1).value === form.get(value2).value;
    if (!validation) {
      return null;
    }

    return { match: validation };
  };
}

export function notEmpty(...inputs: string[]) {
  return (form: AbstractControl) => {
    let hasErrors = false;

    for (const inputName of inputs) {
      const input = form.get(inputName);

      if (input) {
        const value = input.value ?? '';

        if (!value.length) {
          hasErrors = true;
          input.setErrors({required: true});
        }
      }
    }

    if (hasErrors) {
      return {notEmpty: true};
    }
  }
}

// allow text in all languages and spaces
export const onlyText = customPattern(new RegExp("^[a-zA-Z\u00C0-\u1FFF\u2C00-\uD7FF'\\s]*$"), { onlyText: true });

// allow only numbers
export const numbers = customPattern(new RegExp('^[0-9]*$'), { numbers: true });

// allow numbers (e.g. 05544) or text followed by separator and numbers (e.g. LT-05544, LT 05544), or just text and numbers (e.g. LT05544)
// specifically intented to be used on postal code fields
export const textAndNumbersPostal = customPattern(new RegExp('(^[A-Za-z]+.[0-9]+$)|(^[0-9]+$)'), { textAndNumbersPostal: true });

// allows text in any language with - ' or spaces
// specifically intented to be used on city fields
export const textWithSeparators = customPattern(
  new RegExp("^([a-zA-Z\u00C0-\u1FFF\u2C00-\uD7FF]+(?:. |-| |'))*[a-zA-Z\u00C0-\u1FFF\u2C00-\uD7FF]*$"),
  { textWithSeparators: true }
);

// allows text and dashes
// specifically intented to be used on name fields
export const textAndDashes = customPattern(new RegExp("^[a-zA-Z-\u00C0-\u1FFF\u2C00-\uD7FF'\\s]*$"), { textAndDashes: true });

// allow numbers, spaces, and symbols + ( )
export const extendedNumbers = customPattern(new RegExp('^[0-9+()\\s]*$'), { extendedNumbers: true });

// not allow empty space
export const noWhitespace = customPattern(new RegExp('[\\S]+'), { noWhitespace: true });

// allow text, numbers. and symbols ,.:-()
// specifically intented to be used on address fields
export const textAndNumbers = customPattern(new RegExp('^[a-zA-Z\\s0-9,.:()-]*$'), { textAndNumbers: true });

export const alphanumericWithoutSpace = customPattern(new RegExp('^[a-zA-Z0-9]*$'), { alphanumericWithoutSpace: true });

export const decimalNumbers = customPatternDecimal(new RegExp('^\\d+(\\.?\\,?\\d+)?$'), { decimalNumbers: true });

// allow decimal number with up two places precision.
// specifically intended for prices
export const limitedDecimalNumbers = customPatternDecimal(new RegExp('^\\d+(\\.\\d{1,2})?$'), { decimalNumbers: true });

// yyyy-mm-dd
export const validDateFormat = customPattern(new RegExp(/\d{4}-(0[1-9]|1[0-2])-(3[01]|[12][0-9]|0[1-9])/), { dateFormat: true });

function customPatternDecimal(regex: RegExp, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }

    const valid = regex.test(typeof control.value === 'string' ? control.value.replace(',', '.') : control.value);
    return valid ? null : error;
  };
}

/**
 * pattern validation doesn't allow custom error messages,
 * so used code from here: https://stackoverflow.com/questions/48843538/how-to-differentiate-multiple-validators-pattern
 */
function customPattern(regex: RegExp, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }
    const valid = regex.test(control.value);
    return valid ? null : error;
  };
}

// @ts-ignore
export const email = customPattern(new RegExp(/^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/), { email: true });

export interface DiscountValidatorOptionsInterface {
  min: number;
  max: number;
}

export function percentageValidator(options?: DiscountValidatorOptionsInterface): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const { value } = control;
    options = {min: 0, max: 100, ...options};

    if (typeof value === 'undefined') {
      return {
        percentage: {
          value,
          options: options,
        },
      };
    }

    const percentage = parsePercentage(value);

    const resultOfMinValidator = Validators.max(options.max)(control);
    const resultOfMaxValidator = Validators.min(options.min)(control);

    return typeof percentage !== 'undefined' &&
      !isNaN(percentage) &&
      new RegExp('^\\d+(\\.?\\,?\\d+)?(%)?$').test(value) &&
      !resultOfMinValidator &&
      !resultOfMaxValidator
      ? null
      : { percentage: { value, options: options } };
  };
}

export function parsePercentage(persentage: string): number {
  return parseFloat(persentage);
}

export function lessThan(field: string): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.parent) {
      return null;
    }
    const group = control.parent;
    const fieldToCompare = group.get(field);
    return Number(control.value) > Number(fieldToCompare.value) ? { greaterThan: { value: control.value } } : null;
  };
}

export function ucsMaxLength(length: number) {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }
    const actualLength = ucs2decode(control.value.trim()).length;
    const valid = actualLength <= length;
    return valid ? null : {maxlength: {requiredLength: length, actualLength}};
  };
}

export function ucsMinLength(length: number) {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }
    const actualLength = ucs2decode(control.value.trim()).length;
    const valid = actualLength >= length;
    return valid ? null : {minlength: {requiredLength: length, actualLength}};
  };
}

export const containsUpperCaseLetters = customPattern(/\p{Lu}/u, { hasUpperCaseLetters: true });
export const containsLowerCaseLetters = customPattern(/\p{Ll}/u, { hasLowerCaseLetters: true });
export const containsNumbers = customPattern(/\d/, { hasNumbers: true });
export const containsSpecialChars = customPattern(/[! "#\$%&'\(\)\*\+,-\.\/:;<=>\?@\[\]\^_`{\|}~]/, { hasSpecialChars: true });

export function notEqualTo(value: string): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    return control.value !== value ? null : { isEqualTo: { value: value } };
  }
}

export const axOrderNum = customPattern(/^so[0-9]+$/i, { axOrderNum: true })

export const noFilesInProgress = (control: AbstractControl): ValidationErrors => {
  if (!control.value) {
    return null;
  }

  return control.value.some(({ inProgress }) => inProgress) ? { allFilesUploaded: true } : null;
};

export const minDate = (minDate: Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
      return null;
    }

    const date = moment(control.value);
    const minDateFormatted = moment(minDate).format('YYYY-MM-DD');

    return date.isSameOrAfter(minDateFormatted) ? null : { minDate: { minDate: minDateFormatted } };
  };
}

export const maxDate = (maxDate: Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
      return null;
    }

    const date = moment(control.value);
    const maxDateFormatted = moment(maxDate).format('YYYY-MM-DD');

    return date.isSameOrBefore(maxDate) ? null : { maxDate: { maxDate: maxDateFormatted } };
  };
}

export const validStartEndTimes = (startTimeFieldName: string, endTimeFieldName: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
      return null;
    }

    const startTime = moment(control.get(startTimeFieldName).value, 'HH:mm');
    const endTime = moment(control.get(endTimeFieldName).value, 'HH:mm');

    return startTime.isBefore(endTime) ? null : { startTimeNotAfterEndTime: true };
  }
}
