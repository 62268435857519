// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  api: 'https://admin.evo.pricelist7.narbutas.com/api/',
  sentryDSN: 'https://36f817b672014242ae35ea3bdee36842@o485579.ingest.sentry.io/5947447',
  sentryTracesSampleRate: 0,
  release: '9.11.0',
  hidePrices: false,
  gofigure: '//gofigure.evo.pricelist7.narbutas.com',
  xdebugKey: 'VSCODE',
  gitHash: '3923570d983d9108ff0b85264cdce21b7608a021',
  marketingMaterialsURL: '/catalogue/category/marketing-materials/swatch-cards',
  serviceDeskURL: 'https://narbutas.atlassian.net/servicedesk/customer/portal/42/',
  imageResizeURL: 'https://imageresize.evo.pricelist7.narbutas.com/_/',
  googleTagManagerId: 'GTM-T3CBVJB',
  googleTagManagerAuth: 'yBKoAakPKmEdb_4--24bTw',
  googleTagManagerPreview: 'env-264',
};
