<div [inlineSVG]="'/assets/sprite.svg'" hidden></div>
<app-navbar-tree></app-navbar-tree>
<div [ngClass]="{'mw-sm-1280p': loggedIn}">
  <app-header-message></app-header-message>
  <tooltip></tooltip>
  <div appContainer>
    <router-outlet></router-outlet>
  </div>
  <app-maintenance-banner *ngIf="loggedIn"></app-maintenance-banner>
  <app-consent-modal />
</div>
<app-toast-container aria-live="polite" aria-atomic="true"></app-toast-container>
