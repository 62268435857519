import { Injectable, OnInit, ViewChild, Directive } from '@angular/core';
import { SaleCoefficientsService } from './sale-coefficients.service';
import { UserService } from '../../core/services/user/user.service';
import { SaleCoefficientInterface, SaleCoefficientsDataInterface } from '../../core/models/sale-coefficient.model';
import { UserUpdateInterface } from '../../core/models/user.model';
import { LoaderService } from '../../ui-elements/loader/loader.service';
import { LoaderComponent } from '../../ui-elements/loader/loader.component';
import { TranslateService } from '@ngx-translate/core';
import { SaleCoefficientsRange } from '../../core/constants/sale-coefficients-range.constants';
import { CatalogueType } from '../../core/enums/catalogue-type.enum';
import { Observable, zip } from 'rxjs';
import { SaleCoefficientFormatPipe } from '../../shared/pipes/sale-coefficient-format/sale-coefficient-format.pipe';
import { ToastService } from '../../ui-elements/toast/toast.service';
import { CategoryPathInterface } from '../../core/models/category-path.model';

@Directive()
@Injectable()
export class SaleCoefficientsBaseComponent implements OnInit {
  @ViewChild('loader', { static: true }) loader: LoaderComponent;
  protected type = CatalogueType.CATEGORY;
  public currentSaleCoefficient: SaleCoefficientsDataInterface;
  globalCoefficients: SaleCoefficientsDataInterface[] = [];

  saleCoefficientsRange = SaleCoefficientsRange;

  constructor(
    private saleCoefficientsService: SaleCoefficientsService,
    private userService: UserService,
    public loaderService: LoaderService,
    private toastService: ToastService,
    private translator: TranslateService,
    private saleCoefficientFormatPipe: SaleCoefficientFormatPipe
  ) {}

  ngOnInit(): void {
    if (this.type) {
      this.loadData(this.type).subscribe(currentSaleCoefficient => {
        this.currentSaleCoefficient = currentSaleCoefficient;
      });
    }
  }

  loadData(type: CatalogueType): Observable<SaleCoefficientsDataInterface> {
    this.globalCoefficients = [];
    return new Observable(observer => {
      this.loaderService
        .load(
          zip(
            this.saleCoefficientsService.getByType(CatalogueType.CATEGORY).noCache(),
            this.saleCoefficientsService.getByType(CatalogueType.COMPONENT).noCache(),
            this.saleCoefficientsService.getByType(CatalogueType.CUSTOM_MADE_FURNITURE).noCache()
          ),
          this.loader
        )
        .subscribe(
          (result: SaleCoefficientsDataInterface[]) => {
            this.globalCoefficients = result;
            this.globalCoefficients.map(({ categories, defaultSalesCoefficient }) => {
              categories?.map(category => {
                category.saleCoefficient = {
                  ...category.saleCoefficient,
                  articleCategoryPath: category.path,
                  articleCategoryType: category.type,
                  globalApplied: false,
                };

                if (!category.saleCoefficient.saleCoefficient) {
                  category.saleCoefficient.saleCoefficient = defaultSalesCoefficient.defaultSaleCoefficient;
                }

                category.children = category.children.map(child => {
                  child.saleCoefficient = <SaleCoefficientInterface>{
                    ...child.saleCoefficient,
                    articleCategoryPath: child.path,
                    articleCategoryType: child.type,
                    globalApplied: false,
                  };

                  if (!child.saleCoefficient.saleCoefficient) {
                    child.saleCoefficient.saleCoefficient = category.saleCoefficient.saleCoefficient;
                  }

                  return child;
                });
                return category;
              });
            });
            observer.next(this.globalCoefficients.find(({ defaultSalesCoefficient }) => defaultSalesCoefficient.type === type));
            observer.complete();
          },
          err => {
            this.translator.get('SALE_COEFFICIENTS.FAILED_TO_GET_DATA').subscribe(translation => {
              this.toastService.danger(translation);
              console.log(err);
              observer.error(err);
            });
          }
        );
    });
  }

  private applySaleCoefficientsForChildrenRecursive(children, saleCoefficient, globalApplied = false) {
    return children.map(child => {
      child.saleCoefficient = {
        ...child.saleCoefficient,
        saleCoefficient: this.saleCoefficientFormatPipe.transform(saleCoefficient.saleCoefficient),
        globalApplied,
      };

      if (child.children.length) {
        child.children = this.applySaleCoefficientsForChildrenRecursive(child.children, saleCoefficient, globalApplied);
      }

      return child;
    });
  }

  onApply({ children, saleCoefficient }: CategoryPathInterface, globalApplied = false) {
    return this.applySaleCoefficientsForChildrenRecursive(children, saleCoefficient, globalApplied);
  }

  onApplyGlobal() {
    this.currentSaleCoefficient.categories.map(category => {
      category.saleCoefficient.saleCoefficient = this.saleCoefficientFormatPipe.transform(
        this.currentSaleCoefficient.defaultSalesCoefficient.defaultSaleCoefficient
      );
      category.saleCoefficient.globalApplied = true;

      category.children = this.onApply(category, true);

      return category;
    });
  }

  private mapCurrentSaleCoefficientsRecursive(categories: CategoryPathInterface[], defaultSaleCoefficient): SaleCoefficientInterface[] {
    let resultArray: SaleCoefficientInterface[] = [];
    categories?.forEach(category => {
      if (!category) {
        return resultArray;
      }

      if (!category.saleCoefficient?.globalApplied) {
        if (
          +(category.saleCoefficient?.saleCoefficient ?? 0) > 0 &&
          defaultSaleCoefficient[category.type] > 0 &&
          defaultSaleCoefficient[category.type] !== category.saleCoefficient?.saleCoefficient
        ) {
          resultArray.push({
            articleCategoryPath: category.path,
            articleCategoryType: category.type,
            saleCoefficient: this.saleCoefficientFormatPipe.transform(category.saleCoefficient?.saleCoefficient),
          });
        }
      }

      if (category.children.length) {
        resultArray = [...resultArray, ...(this.mapCurrentSaleCoefficientsRecursive(category.children, defaultSaleCoefficient))];
      }
    });

    return resultArray;
  }

  private getMappedDefaultGlobalCoefficients() {
    const mapped = {};
    this.globalCoefficients.map(({ defaultSalesCoefficient }) => defaultSalesCoefficient).forEach(obj => mapped[obj.type] = obj.defaultSaleCoefficient );

    return mapped;
  }

  onSave() {
    this.userService.fromStorage().subscribe(
      res => {
        const userUpdate = <UserUpdateInterface>{};
        userUpdate.defaultSaleCoefficients = this.globalCoefficients.map(({ defaultSalesCoefficient }) => defaultSalesCoefficient);

        const currentSaleCoefficientsMapped = this.mapCurrentSaleCoefficientsRecursive(this.currentSaleCoefficient.categories, this.getMappedDefaultGlobalCoefficients());

        const otherTypesSaleCoefficients = this.globalCoefficients.filter(
          saleCoefficients => saleCoefficients.defaultSalesCoefficient.type !== this.currentSaleCoefficient.defaultSalesCoefficient.type
        );

        const otherSaleCoefficients: SaleCoefficientInterface[] = this.mapCurrentSaleCoefficientsRecursive(otherTypesSaleCoefficients[0].categories, this.getMappedDefaultGlobalCoefficients());

        userUpdate.saleCoefficients = [...currentSaleCoefficientsMapped, ...otherSaleCoefficients];

        this.userService.update(res.id, userUpdate).subscribe(
          () => {
            this.translator.get('SALE_COEFFICIENTS.SAVED').subscribe(translation => {
              this.toastService.success(translation);
            });
          },
          err => {
            this.translator.get('SALE_COEFFICIENTS.FAILED_TO_SAVE').subscribe(translation => {
              this.toastService.danger(translation);
              console.log(err);
            });
          }
        );
      },
      err => {
        this.translator.get('SALE_COEFFICIENTS.FAILED_TO_GET_USER').subscribe(translation => {
          this.toastService.danger(translation);
          console.log(err);
        });
      }
    );
  }

  getDefaultSaleCoefficient() {
    return this.currentSaleCoefficient ? this.currentSaleCoefficient.defaultSalesCoefficient.defaultSaleCoefficient : 1;
  }

  setDefaultSaleCoefficient(value) {
    if (this.currentSaleCoefficient) {
      this.currentSaleCoefficient.defaultSalesCoefficient.defaultSaleCoefficient = +value;
    }
  }

  onCancel() {
    if (this.type) {
      this.loadData(this.type).subscribe(currentSaleCoefficient => {
        this.currentSaleCoefficient = currentSaleCoefficient;
        this.translator.get('SALE_COEFFICIENTS.REFRESHED_DATA').subscribe(translation => {
          this.toastService.success(translation);
        });
      });
    }
  }

  apply(element, event) {
    element.saleCoefficient.saleCoefficient = event;
    element.saleCoefficient.globalApplied = false;
  }

  getDescriptionKey(): string {
    let key = '';

    switch (this.type) {
      case CatalogueType.CATEGORY:
        key = 'CATEGORIES';
        break;
      case CatalogueType.COMPONENT:
        key = 'COMPONENTS';
        break;
      case CatalogueType.CUSTOM_MADE_FURNITURE:
        key = 'CUSTOM_MADE_FURNITURE';
        break;
    }

    return `SALE_COEFFICIENTS.DESCRIPTION.${key}`;
  }
}
