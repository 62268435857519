<app-generic-modal cssClasses="transparent-body component-host-scrollable"
                   headerClasses="px-0 pb-0"
                   bodyClasses="p-0"
                   class="component-host-scrollable">
  <ng-template appModalHeader>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col">
          <h2 class="modal-header__title fw-bold px-4">
            {{ 'CREATE_DOCUMENT.EXPORT_DOCUMENT' | translate }}
          </h2>
          <p class="modal-header__sub-title px-4">
            {{ order?.title }}
          </p>
        </div>
      </div>
      <div class="row g-0 border-top">
        <div class="col">
          <div class="row--custom-padding navigation-tabs" #bounds>
            <ul class="nav nav-pills d-flex ps-2 pe-3 m-0 me-2">
              <li class="nav-item cursor-pointer" (click)="switchTab(tabs.template)">
                <span class="nav-link" [ngClass]="{'active': activeTab === tabs.template}">{{ tabs.template.title | translate }}</span>
              </li>
              <li
                class="nav-item cursor-pointer"
                tooltipOpener
                [tooltipContent]="getTooltipTranslationKey() | translate"
                [tooltipDisabled]="allowGenerateDocument"
                [tooltipPosition]="'bottom'"
                [tooltipArrowPosition]="'top'"
                [tooltipBounds]="bounds"
                [additionalClasses]="'tooltip--orange tooltip--create-document'"
                (click)="openPreviewTab()"
              >
                <span [ngClass]="{'active': activeTab === tabs.preview}" class="nav-link">{{ tabs.preview.title | translate }}</span>
              </li>
              <li
                class="ms-auto nav-item cursor-pointer"
                [ngClass]="{ 'is-loading': isProcessingDocumentDownload }"
                >
                <div ngbDropdown class="d-flex h-100 align-items-center" placement="bottom-end">
                  <app-loader #downloadLoader [cssClasses]="'loader--white'"></app-loader>
                  <button class="download-dropdown dropdown-toggle btn bg-gray-200 color-gray-600 no-outline" ngbDropdownToggle>{{ 'CREATE_DOCUMENT.TEMPLATE.DOWNLOAD' | translate }}</button>
                  <div class="download-drop-down-menu shadow" ngbDropdownMenu>
                    <button
                      class="btn btn-link color-gray hover-color-black hover-bg-gray-100 no-outline d-flex w-100 py-1"
                      (click)="onDocumentDownload(documentFileFormats.PDF)"
                      ngbDropdownItem
                    >
                      <div class="py-2 d-flex">
                        <i class="icon ni-download-bracket me-3"></i>
                        <span>
                          {{ 'CREATE_DOCUMENT.DOWNLOAD_AS_PDF' | translate }}
                        </span>
                      </div>
                    </button>
                    <button
                      class="btn btn-link color-gray hover-color-black hover-bg-gray-100 no-outline d-flex w-100 py-1"
                      (click)="onDocumentDownload(documentFileFormats.XLSX)"
                      ngbDropdownItem
                    >
                      <div class="py-2 d-flex">
                        <i class="icon ni-download-bracket me-3"></i>
                        <span>
                          {{ 'CREATE_DOCUMENT.DOWNLOAD_AS_XLSX' | translate }}
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template appModalBody>
    <div class="tab-content position-relative pt-0 px-3 mx-2 pb-4 mb-2" [class.document-margin]="activeTab.id === tabs.preview.id">
      <app-loader #previewLoader></app-loader>
      <app-loader [loading]="loading"></app-loader>
      <ng-container [ngSwitch]="activeTab.id">
        <div class="tab-pane" *ngSwitchCase="tabs.template.id">
          <app-create-document-document-template
            #documentTemplateComponent
            *ngIf="selectedTemplate && templates"
            [data]="data"
            [selectedTemplate]="selectedTemplate"
            (templateChange)="onTemplateChange($event)"
            [loading]="loading"
            [order]="order"
            [showErrors]="showErrors"
          ></app-create-document-document-template>
          <div class="text-center p-5" *ngIf="!(templates && templates.length) && !previewLoader.loading && !loading">
            <p>{{ 'CREATE_DOCUMENT.TEMPLATE.TEMPLATE_ERROR' | translate }}</p>
          </div>
        </div>
        <div class="tab-pane bg-white" *ngSwitchCase="tabs.preview.id">
          <div class="preview-html" *ngIf="previewHTML !== null" [innerHTML]="previewHTML | safeHtml"></div>
          <div class="text-center p-5" *ngIf="previewHTML === null && !previewLoader.loading">
            <p>{{ 'CREATE_DOCUMENT.TEMPLATE.PREVIEW_ERROR' | translate }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</app-generic-modal>
