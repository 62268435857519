<ng-template #cookieModal>
  <app-generic-modal [closable]="false" [mobileResponsiveFooter]="true">
    <ng-template appModalHeader>
      <h2 class="mb-0 modal-header__title">
        {{ 'COOKIE_CONSENT.TITLE' | translate }}
      </h2>
    </ng-template>
    <ng-template appModalBody>
      <p class="mb-0">
        {{ 'COOKIE_CONSENT.TEXT' | translate }} <a href="#" (click)="openTermsModal($event)">{{ 'COOKIE_CONSENT.LINK' | translate }}.</a>
      </p>
      <div class="d-flex pt-3">
        <app-check-mark [disabled]="true" name="must" [text]="'COOKIE_CONSENT.REQUIRED' | translate" [value]="cookiesMust" class="me-3"></app-check-mark>
        <app-check-mark name="optional" [text]="'COOKIE_CONSENT.STATISTICS' | translate" [value]="cookiesStatistics" (changed)="cookiesStatistics = $event"></app-check-mark>
      </div>
    </ng-template>
    <ng-template appModalFooter>
      <button (click)="onDismiss()" class="btn btn-secondary btn-lg rounded-0 px-5" id="consentUsePartial">{{ 'COOKIE_CONSENT.BUTTON_USE_SELECTED' | translate }}</button>
      <button (click)="onAgree()" class="btn btn-lg btn-primary rounded-0 w-25" id="consentUseAll">{{ 'COOKIE_CONSENT.BUTTON_USE_ALL' | translate }}</button>
    </ng-template>
  </app-generic-modal>
</ng-template>
