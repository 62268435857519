<nav aria-label="breadcrumb">
  <ul class="list-unstyled d-flex align-items-center m-0 fw-bold">
    <li class="d-flex align-items-center"
        *ngFor="let breadcrumb of breadcrumbs; let last = last"
    >
      <ng-container *ngIf="breadcrumb.uri || breadcrumb.onClick; else text">
        <a class="hover-color-primary-700"
           [href]="breadcrumb.uri ?? '#'"
           [class.color-gray-600]="!breadcrumb.isPageTitle"
           [class.color-gray-500]="breadcrumb.isPageTitle"
           (click)="breadcrumb.onClick ? breadcrumb.onClick(breadcrumb, $event): null;"
        >{{breadcrumb.title | titlecase}}</a>
      </ng-container>
      <ng-template #text>
        <span
          [class.color-gray-600]="!breadcrumb.isPageTitle"
          [class.color-gray-500]="breadcrumb.isPageTitle"
        >{{breadcrumb.title | titlecase}}</span>
      </ng-template>
      <i class="icon ni-chevron-right color-gray-400" *ngIf="!last || withLeadingChevron" data-testid="chevronIcon"></i>
    </li>
  </ul>
</nav>
