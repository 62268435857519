<div class="products-list-table-controls d-flex" #bounds>
  <app-loader #loader [cssClasses]="'loader--white'"></app-loader>

  <div class="btn-group btn-group--buttons-with-text ps-3 ms-1" role="group">
    <ng-container *permissionsElementVisibilityDecisionMaker="[permissionActions.CAN_MODIFY_DISCOUNTS]">
      <button
        class="btn btn-light ms-1 d-flex justify-content-center"
        *ngIf="selectedCount.orderArticles && isControlAllowed(actionType.DISCOUNT)"
        (click)="onDiscount()"
      >
        <i class="icon ni-discount me-3"></i>
        <span>
          {{ 'ORDER_PRODUCTS_LIST.CONTROLS.DISCOUNT' | translate }}
        </span>
      </button>
    </ng-container>
    <button
      class="btn btn-light ms-1 d-flex"
      *ngIf="allowedSelectedItemsActions.modify && allowedSelectedItemsActions.edit && isControlAllowed(actionType.EDIT)"
      (click)="onModify()"
    >
      <i class="icon ni-space-box me-2"></i>
      <span>
        {{ 'ORDER_PRODUCTS_LIST.CONTROLS.EDIT' | translate }}
      </span>
    </button>

    <button
      class="btn btn-light ms-1 d-flex"
      *ngIf="allowedSelectedItemsActions.group && isControlAllowed(actionType.GROUP) && shouldAllowGroup"
      (click)="onGroupItems()"
      tooltipOpener
      tooltipContent="{{ 'ORDER_PRODUCTS_LIST.CONTROLS.POPOVER.GROUP_ITEMS' | translate }}
           {{ toGroupCount | i18nPlural: singularPluralMap | translate: { count: toGroupCount } }}"
      [tooltipPosition]="'bottom'"
      [tooltipArrowPosition]="'top'"
      [tooltipDisabled]="!shouldAllowGroup"
      [additionalClasses]="'tooltip--black'"
    >
      <i class="icon ni-folder-add me-2"></i>
      <span>
        {{ 'ORDER_PRODUCTS_LIST.CONTROLS.GROUP' | translate }}
      </span>
    </button>

    <button
      class="btn btn-light ms-1 d-flex"
      *ngIf="allowedSelectedItemsActions.ungroup && isControlAllowed(actionType.UNGROUP)"
      (click)="onUngroup()"
    >
      <span class="d-flex align-items-center">
        <i class="icon ni-folder-remove me-2"></i>
        {{ 'ORDER_PRODUCTS_LIST.CONTROLS.UNGROUP' | translate }}
      </span>
    </button>

    <button
      class="btn btn-light ms-1 d-flex"
      *ngIf="shouldAllowExportToObx() && isControlAllowed(actionType.EXPORT_TO_OBX)"
      (click)="onExportToObx()"
    >
      <span class="d-flex align-items-center">
        <i class="icon ni-download me-2"></i>
        {{ 'ORDER_PRODUCTS_LIST.CONTROLS.EXPORT_TO_OBX' | translate }}
      </span>
    </button>
  </div>

  <div
    class="btn-group ps-3 ms-1"
    role="group"
    *ngIf="
      allowedSelectedItemsActions.modify &&
      (isControlAllowed(actionType.CUT) ||
        isControlAllowed(actionType.COPY) ||
        isControlAllowed(actionType.PASTE) ||
        isControlAllowed(actionType.REMOVE))
    "
  >
    <button
      class="d-flex btn btn-light ms-1"
      *ngIf="isControlAllowed(actionType.CUT)"
      [disabled]="isControlDisabled(actionType.CUT)"
      (click)="onCut()"
      tooltipOpener
      [tooltipContent]="getCutControlTooltipContent()"
      [tooltipPosition]="'bottom'"
      [tooltipArrowPosition]="'top'"
      [additionalClasses]="'tooltip--black'"
    >

      <i class="icon ni-cut"></i>
    </button>
    <button
      class="d-flex btn btn-light ms-1"
      (click)="onCopy()"
      *ngIf="isControlAllowed(actionType.COPY)"
      [disabled]="isControlDisabled(actionType.COPY)"
      tooltipOpener
      [tooltipContent]="getCopyControlTooltipContent()"
      [tooltipPosition]="'bottom'"
      [tooltipArrowPosition]="'top'"
      [additionalClasses]="'tooltip--black'"
    >
    <i class="icon ni-content-copy"></i>
    </button>
    <button
      class="d-flex btn btn-light ms-1 no-outline"
      [ngClass]="{ 'control-btn--active': clipboardCount }"
      *ngIf="isControlAllowed(actionType.PASTE) && shouldAllowPaste()"
      (click)="onPaste()"
      [disabled]="!clipboardCount || loading"
      tooltipOpener
      [tooltipHoverableContent]="true"
      [tooltipDisabled]="!clipboardCount"
      [tooltipContent]="clipboardItemsPopover"
      [tooltipPosition]="'bottom'"
      [tooltipArrowPosition]="'top'"
      [additionalClasses]="'tooltip--black tooltip--component'"
      arrowClasses="border-0 bg-gray-200"
      contentClasses="w-100 mw-35r p-0 border-0 shadow bg-gray-200 overflow-auto mvh-sm-75"
    >
      <i class="icon ni-content-paste d-flex position-relative">
        <app-notification [notifications]="clipboardCount" textClass="color-white"></app-notification>
      </i>
    </button>

    <button class="d-flex btn btn-light ms-1" (click)="onRemove()" *ngIf="isControlAllowed(actionType.REMOVE)">
      <i class="icon ni-delete"></i>
    </button>
  </div>

  <div *ngIf="clipboardCount > 0 && !selectedCount.total && isControlAllowed(actionType.PASTE)" class="ps-3 ms-1">
    <button
      class="d-flex btn btn-light ms-1 no-outline"
      (click)="onPaste()"
      [disabled]="!clipboardCount || loading"
      tooltipOpener
      [tooltipHoverableContent]="true"
      [tooltipDisabled]="!clipboardCount"
      [tooltipContent]="clipboardItemsPopover"
      [tooltipPosition]="'bottom'"
      [tooltipArrowPosition]="'top'"
      [additionalClasses]="'tooltip--black tooltip--component'"
      arrowClasses="border-0 bg-gray-200"
      contentClasses="w-100 mw-35r p-0 border-0 shadow bg-gray-200 overflow-auto mvh-sm-75"
    >
      <i class="icon ni-content-paste d-flex position-relative">
        <app-notification [notifications]="clipboardCount" textClass="color-white"></app-notification>
      </i>
      <span class="ms-3">
        {{ 'CLIPBOARD.PASTE' | translate }}
      </span>
    </button>
  </div>

  <div class="btn-group ps-3 ms-1" *ngIf="isControlAllowed(actionType.CREATE_NEW_CLAIM) || isControlAllowed(actionType.EXPORT_TO_PDF)">
    <a
      *ngIf="order?.axOrderNum && isControlAllowed(actionType.CREATE_NEW_CLAIM)"
      [routerLink]="['/', inquiriesRoutePath.ROOT, inquiriesRoutePath.CLAIMS, 'new', order.axOrderNum]"
      class="btn btn-light"
    >
      <span class="d-flex text-nowrap">
        <i class="icon ni-plus me-2"></i>
        {{ 'INQUIRIES.CLAIMS.LIST.NEW_CLAIM' | translate }}
      </span>
    </a>

    <button
      *ngIf="isControlAllowed(actionType.EXPORT_TO_PDF)"
      class="btn color-white bg-gray-600 hover-bg-gray-700 ms-2"
      (click)="onExportOrderToPdf()"
    >
      <span class="d-flex text-nowrap">
        <i class="icon ni-download me-2"></i>
        {{ 'ORDER_PRODUCTS_LIST.CONTROLS.EXPORT_TO_PDF' | translate }}
      </span>
    </button>
  </div>

  <div
    class="btn-group ps-3 ms-1"
    role="group"
    *ngIf="isControlAllowed(actionType.ADD_ITEMS) || isControlAllowed(actionType.ADD_EXTRA_ITEMS) || isControlAllowed(actionType.IMPORT)"
  >
    <div
      ngbDropdown
      *ngIf="isControlAllowed(actionType.ADD_EXTRA_ITEMS) || isControlAllowed(actionType.ADD_ITEMS)"
      placement="bottom-end"
      container="body"
    >
      <button ngbDropdownToggle class="btn btn-primary no-outline ms-1" [disabled]="rowsFilterEnabled"
      tooltipOpener
      [tooltipDisabled]="!rowsFilterEnabled"
      [tooltipContent]="'ORDER_PRODUCTS_LIST.CONTROLS.DISABLED_ADD_OR_IMPORT_TOOLTIP' | translate"
      additionalClasses="tooltip--black"
      tooltipPosition="bottom"
      tooltipArrowPosition="top"
      data-testid="addItemsDropdown"
      >
        <span>{{ 'ORDER_PRODUCTS_LIST.CONTROLS.ADD' | translate }}</span>
      </button>

      <div ngbDropdownMenu class="dropdown-menu shadow" [appParentBoundary]="bounds">
        <button class="dropdown-item btn btn-secondary no-outline d-flex border-bottom"
                (click)="onAddItem()"
                *ngIf="isControlAllowed(actionType.ADD_STANDARD_ITEM)">
          <i class="icon ni-add-lg me-3"></i>
          <span class="text-nowrap">
            {{ 'ORDER_PRODUCTS_LIST.CONTROLS.ADD_DROPDOWN.ITEM' | translate }}
          </span>
        </button>
        <button class="dropdown-item btn btn-secondary no-outline d-flex  border-bottom"
                (click)="onAddNonStandardItem()"
                *ngIf="isControlAllowed(actionType.ADD_NON_STANDARD_ITEM) && (
                  FeatureFlags.oldNonStandardDropdownItemEnabled
                  || !FeatureFlags.oldNonStandardsRestrictionsEnabled 
                  || (FeatureFlags.oldNonStandardsRestrictionsEnabled && !userService.isDealer())
                )"
        >
          <i class="icon ni-add-task me-3"></i>
          <span class="text-nowrap">
            {{ 'ORDER_PRODUCTS_LIST.CONTROLS.ADD_DROPDOWN.NON_STANDARD_ITEM' | translate }}
          </span>
        </button>
        <button class="dropdown-item btn btn-secondary no-outline d-flex  border-bottom"
                (click)="onAddOtherSupplierItem()"
                *ngIf="isControlAllowed(actionType.ADD_OTHER_SUPPLIER_ITEM)"
        >
          <i class="icon ni-blur-circular me-3"></i>
          <span class="text-nowrap">
            {{ 'ORDER_PRODUCTS_LIST.CONTROLS.ADD_DROPDOWN.OTHER_SUPPLIER_ITEM' | translate }}
          </span>
        </button>

        <button
          class="dropdown-item btn btn-secondary no-outline d-flex border-bottom"
          (click)="onExtraItemAdd(extraItemTypes.PAGE_BREAK)"
          *ngIf="isControlAllowed(actionType.ADD_PAGE_BREAK)"
        >
          <i class="icon ni-file me-3"></i>
          <span class="text-nowrap">
            {{ 'ORDER_PRODUCTS_LIST.CONTROLS.ADD_DROPDOWN.PAGE_BREAK' | translate }}
          </span>
        </button>
        <button class="dropdown-item btn btn-secondary no-outline d-flex border-bottom"
                (click)="onExtraItemAdd(extraItemTypes.TEXT)"
                *ngIf="isControlAllowed(actionType.ADD_TEXT)">
          <i class="icon ni-text-block me-3"></i>
          <span class="text-nowrap">
            {{ 'ORDER_PRODUCTS_LIST.CONTROLS.ADD_DROPDOWN.TEXT' | translate }}
          </span>
        </button>
        <button
          class="dropdown-item btn btn-secondary no-outline d-flex border-bottom"
          (click)="onExtraItemAdd(extraItemTypes.GROUP)"
          *ngIf="availableActions.includes(actionType.GROUP) && shouldAllowAddGroup"
        >
          <i class="icon ni-folder-add me-3"></i>
          <span class="text-nowrap">
            {{ 'ORDER_PRODUCTS_LIST.CONTROLS.ADD_DROPDOWN.GROUP' | translate }}
          </span>
        </button>
      </div>
    </div>
    <div
      ngbDropdown #importDropdown="ngbDropdown"
      *ngIf="isControlAllowed(actionType.IMPORT)"
      placement="bottom-end"
      container="body"
    >
      <button ngbDropdownToggle class="btn btn-primary no-outline ms-1" [disabled]="importInProgress || rowsFilterEnabled"
        tooltipOpener
        [tooltipDisabled]="!rowsFilterEnabled"
        [tooltipContent]="'ORDER_PRODUCTS_LIST.CONTROLS.DISABLED_ADD_OR_IMPORT_TOOLTIP' | translate"
        additionalClasses="tooltip--black"
        tooltipPosition="bottom"
        tooltipArrowPosition="top"
      >
        <span>{{ 'ORDER_PRODUCTS_LIST.CONTROLS.IMPORT' | translate }}</span>
      </button>

      <div ngbDropdownMenu class="dropdown-menu shadow" [appParentBoundary]="bounds">
        <button class="dropdown-item btn btn-secondary no-outline d-flex border-bottom" (click)="obxUploadInput.click()">
          <i class="icon ni-upload me-3"></i>
          <span class="text-nowrap">{{ 'ORDER_PRODUCTS_LIST.CONTROLS.IMPORT_FROM' | translate: { value: 'OBX' } }}</span>
        </button>
        <input class="d-none" id="import-obx" type="file" (change)="onFileSelect($event); importDropdown.close()" accept=".obx" #obxUploadInput />

        <button class="dropdown-item btn btn-secondary no-outline d-flex border-bottom" (click)="obkUploadInput.click()">
          <i class="icon ni-upload me-3"></i>
          <span class="text-nowrap">{{ 'ORDER_PRODUCTS_LIST.CONTROLS.IMPORT_FROM' | translate: { value: 'OBK' } }}</span>
        </button>
        <input class="d-none" id="import-obk" type="file" (change)="onFileSelect($event); importDropdown.close()" accept=".obk" #obkUploadInput />

        <button class="dropdown-item btn btn-secondary no-outline d-flex border-bottom"
                (click)="openCsvUploadModal()"
                *ngIf="!userService.isPM()"
        >
          <i class="icon ni-upload me-3"></i>
          <span class="text-nowrap">{{ 'ORDER_PRODUCTS_LIST.CONTROLS.IMPORT_FROM' | translate: { value: 'CSV' } }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Copied Articles Popover template -->
<ng-template #clipboardItemsPopover>
  <div class="copied-articles-list">
    <ng-container *ngTemplateOutlet="clipboardItemsList; context: { $implicit: clipboardItems }"></ng-container>
  </div>
</ng-template>
<!-- Copied Articles Popover template end -->

<ng-template #clipboardItemsList let-rows>
  <ng-container *ngFor="let row of rows" [ngSwitch]="row.rowType">
    <div class="copied-articles-list__item border-bottom">
      <ng-container  *ngSwitchCase="rowTypes.PRODUCT">
        <ng-container [ngSwitch]="row.orderArticleType">
          <app-order-articles-price-request-item-row
            *ngSwitchCase="orderArticleTypes.PRICE_REQUEST_ITEM"
            class="d-block"
            [style.margin-left.px]="row.groupLevel * 20"
            [row]="row"
            [limitedView]="true"
          ></app-order-articles-price-request-item-row>
          <app-order-articles-list-row
              *ngSwitchDefault
              class="d-block"
              [style.margin-left.px]="row.groupLevel * 20"
              [row]="row"
              [limitedView]="true"
          ></app-order-articles-list-row>
        </ng-container>
      </ng-container>

      <app-order-articles-list-extra-row
        *ngSwitchCase="rowTypes.EXTRA"
        [style.margin-left.px]="row.groupLevel * 20"
        class="d-block"
        [limitedView]="true"
        [row]="row"
      ></app-order-articles-list-extra-row>

      <app-order-articles-price-request-items-group
        *ngSwitchCase="rowTypes.LOCKED_PRICE_REQUEST_ITEMS_GROUP"
        [style.margin-left.px]="row.groupLevel * 20"
        class="d-block"
        [limitedView]="true"
        [row]="row"
      ></app-order-articles-price-request-items-group>
      
      <app-order-articles-list-group
        *ngSwitchCase="rowTypes.GROUP"
        [style.margin-left.px]="row.groupLevel * 20"
        class="d-block"
        [limitedView]="true"
        [row]="row"
      ></app-order-articles-list-group>
    </div>
    <ng-container
      *ngTemplateOutlet="clipboardItemsList; context: { $implicit: row.children && row.children.length ? row.children : [] }"
    ></ng-container>
  </ng-container>
</ng-template>
