<div class="card border-0 d-none d-md-block" data-testid="desktopView">
  <div class="card-header py-0 px-2">
    <ul *ngIf="tabs" class="nav nav-pills">
      <li *ngFor="let item of tabs" class="nav-item cursor-pointer">
        <a class="nav-link"
          (click)="select($event, item)"
          [href]="getTermsUrl(item.uri)"
          [class.active]="selected && item.uri === selected.uri"
        >
          {{ item.basicTranslation.title }}
        </a>
      </li>
    </ul>
  </div>
  <div class="card-body bg-white overflow-auto p-4" *ngIf="selected">
    <div [innerHTML]="selected.translation.description | safeHtml"></div>
  </div>
</div>

<div class="card border-0 d-block d-md-none h-100 bg-gray-300" data-testid="mobileView">
  <div ngbAccordion [closeOthers]="true">
    <div
      *ngFor="let item of tabs"
      ngbAccordionItem
      #accordionItem="ngbAccordionItem"
      class="bg-white"
      [collapsed]="item.id !== selected.id"
      (hide)="onCollapseStart(item)"
      (hidden)="onCollapseFinish(item)"
    >
      <div ngbAccordionHeader>
        <div
          ngbAccordionToggle
          class="p-3 d-flex justify-content-between align-items-center cursor-pointer fw-bold"
          (click)="item.id !== selected.id ? select($event, item): null"
          data-testid="toggleAccordion"
        >
          <h3 
            class="m-0 color-gray-600" 
            [class.color-primary-600]="!accordionItem.collapsed"
            data-testid="togglerText"
          >
            {{ item.basicTranslation.title }}
          </h3>
          <i 
            class="icon ms-2"
            [class.color-primary-600]="!accordionItem.collapsed"
            [ngClass]="accordionItem.collapsed ? 'ni-expand-more' : 'ni-expand-less'"
            data-testid="expandIcon"
          ></i>
        </div>
      </div>

      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="border-top p-0">
          <ng-template>
            <div 
              *ngIf="item.id === selected.id" 
              [innerHTML]="selected.translation.description | safeHtml" 
              class="overflow-auto px-3 py-4"
            ></div>

            <div *ngIf="item.id !== selected.id && !item.isCollapsing" class="bg-white p-5 position-relative">
              <app-loader [loading]="true" data-testid="contentLoader"></app-loader>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
