<div ngbDropdown [ngClass]="class" class="d-inline-block" (openChange)="openChangeHandler($event)">
  <div
    ngbDropdownToggle
    class="d-inline-flex justify-content-between align-items-center w-100 my-2 bg-gray-200 p-2 rounded-1 cursor-pointer color-gray-600"
    data-testid="dropdownToggler"
  >
    <span 
      [class.d-none]="isOpen"
      class="w-100 text-truncate"
      data-testid="selectedOption"
    >
      {{ value ? value[textField] : placeholder }}
    </span>
    <input 
      #filter 
      [class.d-none]="!isOpen" 
      type="text" 
      class="form-control w-100 border-0 p-0" 
      [(ngModel)]="filterValue"
      (keyup)="findOptions($event)" 
      autocomplete="new-password" 
      [disabled]="isDisabled"
      data-testid="filterInput"
    />
  </div>
  <div ngbDropdownMenu class="shadow-sm">
    <button
      class="btn"
      [disabled]="isDisabled || (disabledField && item[disabledField])"
      *ngFor="let item of filteredOptions"
      ngbDropdownItem
      (click)="selectValue(item)"
      [class.active]="value && value[valueField] === item[valueField]"
      [class.btn-disabled]="isDisabled || (disabledField && item[disabledField])"
      data-testid="dropdownOption"
    >
      {{ item[textField] }}
    </button>
  </div>
</div>
