import { Component, Input } from '@angular/core';
import Dropzone from 'dropzone';
import mime from 'mime';
import { FileUploadErrorEnum } from '../../../../core/enums/file-upload-error.enum';

@Component({
  selector: 'app-files-input-base',
  imports: [],
  template: '',
})
export class FilesInputBaseComponent {
  @Input() set acceptedFiles(value: string) {
    if (!value.includes(this.standardSnippetExtension)) {
      value += `,${this.standardSnippetExtension}`;
    }

    this._acceptedFiles = value;
  }

  private _acceptedFiles: string = '';
  get acceptedFiles(): string {
    return this._acceptedFiles;
  }

  dropzoneInstance: Dropzone;
  fileUploadError: FileUploadErrorEnum = FileUploadErrorEnum.NONE;
  readonly standardSnippetExtension = '.png';

  onKeyboardPaste(event: ClipboardEvent) {
    const clipboardItems = event.clipboardData?.items;

    if (!clipboardItems.length) {
      return;
    }

    const addedFilesForUpload: Dropzone.DropzoneFile[] = [];

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];
      const blob = item.getAsFile();
      const extension = `.${mime.getExtension(item.type)}`;
      const isImage = item.type.startsWith('image/');
      const allImagesAllowed = this.acceptedFiles.includes('image/*');

      const isFileAccepted = this.acceptedFiles.includes(extension) || (isImage && allImagesAllowed);

      if (!isFileAccepted) {
        continue;
      }

      const filename = `${Date.now()}${extension}`;
      const file = new File([blob], filename, { type: blob.type });

      const dropzoneFile: Dropzone.DropzoneFile = Object.assign(file, {
        accepted: true,
        status: 'queued',
        previewElement: null,
        previewTemplate: null,
        previewsContainer: null,
      });

      this.dropzoneInstance.addFile(dropzoneFile);
      addedFilesForUpload.push(dropzoneFile);
    }

    if (addedFilesForUpload.length) {
      this.dropzoneInstance.emit('addedfiles', addedFilesForUpload);

      return;
    }

    this.fileUploadError = FileUploadErrorEnum.UNSUPPORTED_FILE_TYPE;
  }

  async onButtonPaste() {
    try {
      const clipboardItems = await navigator.clipboard.read();

      if (!clipboardItems.length) {
        return;
      }

      const imageMimeType = clipboardItems[0].types.find((type) => type.startsWith('image/'));

      if (!imageMimeType) {
        this.fileUploadError = FileUploadErrorEnum.UNSUPPORTED_FILE_TYPE;

        return;
      }

      const extension = `.${mime.getExtension(imageMimeType)}`;

      const isFileAccepted = ['image/*', extension].some((type) => this.acceptedFiles.includes(type));

      if (!isFileAccepted) {
        this.fileUploadError = FileUploadErrorEnum.UNSUPPORTED_FILE_TYPE;

        return;
      }

      const blob = await clipboardItems[0].getType(imageMimeType);
      const filename = `${Date.now()}${extension}`;

      const file = new File([blob], filename, { type: imageMimeType });
      const dropzoneFile: Dropzone.DropzoneFile = Object.assign(file, {
        accepted: true,
        status: 'queued',
        previewElement: null,
        previewTemplate: null,
        previewsContainer: null,
      });

      this.dropzoneInstance.addFile(dropzoneFile);
      this.dropzoneInstance.emit('addedfiles', [dropzoneFile]);   
    } catch (error) {}
  }
}
