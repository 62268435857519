import { FileData } from './file-data.model';

export enum PriceRequestItemTypeEnum {
    BASED_ON_STANDARD = 'based_on_standard',
    CUSTOM = 'custom'
}

export enum PriceRequestItemStatusEnum {
    IN_PROGRESS = 'in_progress',
    WAITING_FOR_RESPONSE = 'waiting_for_response',
    COMPLETED = 'completed',
    CANCELED = 'canceled',
    EXPIRED = 'expired'
}

export enum PriceRequestItemFileTypeEnum {
    GENERAL = 'general',
    CLARIFICATION = 'clarification'
}

export const PriceRequestItemStatusTranslationMap = {
    [PriceRequestItemStatusEnum.IN_PROGRESS]: 'INQUIRIES.PRICE_REQUESTS.STATUS.IN_PROGRESS',
    [PriceRequestItemStatusEnum.WAITING_FOR_RESPONSE]: 'INQUIRIES.PRICE_REQUESTS.STATUS.WAITING_FOR_RESPONSE',
    [PriceRequestItemStatusEnum.COMPLETED]: 'INQUIRIES.PRICE_REQUESTS.STATUS.COMPLETED',
    [PriceRequestItemStatusEnum.CANCELED]: 'INQUIRIES.PRICE_REQUESTS.STATUS.CANCELED',
    [PriceRequestItemStatusEnum.EXPIRED]: 'INQUIRIES.PRICE_REQUESTS.STATUS.EXPIRED',
  };

export interface PriceRequestItemFileInterface {
    id: number;
    type: PriceRequestItemFileTypeEnum;
    file: FileData;

    // used for temporary files, while they are being uploaded
    inProgress: boolean;
}

export interface PriceRequestItemInterface {
    id: number;
    type: PriceRequestItemTypeEnum;
    status: PriceRequestItemStatusEnum | null;
    isCompleted: boolean;
    title: string | null;
    quantity: number | null;
    description: string | null;
    dimensions: string | null;
    finishes: string | null;
    clarificationQuestion: string | null;
    clarificationAnswer: string | null;
    code: string | null;
    articles: PriceRequestItemArticleInterface[];
    pricelistPrice: number | null; // Use only in CMPR interfaces, but not in the Order related ones!
    materials: string | null;
    customMadePriceRequestItemFiles: PriceRequestItemFileInterface[];
    jiraIssueKey: string | null;
    customMadePriceRequestId: number;
    salePrice: number | null;
    clarificationAnsweredAtTimestamp: number | null;
    clarificationAnsweredByFullName: string | null;
    purchasePrice: number | null;
}

export interface PriceRequestItemArticleDimensionInterface {
    depth: string | null;
    height: string | null;
    width: string | null;
}

export interface PriceRequestItemArticleInterface {
    code: string;
    price: number;
    title: string;
    finishes: string[];
    fullCode: string;
    quantity: number;
    dimensions: {
        millimeters?: PriceRequestItemArticleDimensionInterface;
        inches?: PriceRequestItemArticleDimensionInterface;
    };
}
