import { Directive, HostListener, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewImageModalComponent } from './preview-image-modal/preview-image-modal.component';
import { FileData } from '../../core/models/file-data.model';

@Directive({
  selector: '[previewFile]'
})
export class PreviewFileDirective {
  @Input() previewFile: FileData;

  constructor(private modalService: NgbModal) {
  }

  @HostListener('click') onClick() {
    const imageFileExtension = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg'];

    if (imageFileExtension.includes(this.previewFile.extension)) {
      const modalRef = this.modalService.open(PreviewImageModalComponent, { size: 'lg', centered: true });
      modalRef.componentInstance.image = this.previewFile;
    } else {
      const a = document.createElement('a');
      a.href = this.previewFile.url;
      a.download = this.previewFile.filename;
      a.target = '_blank';
      a.click()
      a.remove();
    }
  }
}
