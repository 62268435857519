export enum FieldType {
  IMG = 'img',
  SYSTEM = 'system',
  CODE = 'code',
  CATEGORY = 'category',
  SHORT_TEXT = 'shortText', //Description
  ID = 'id',

  MATERIALS_IMAGE = 'materialsImage',
  MATERIALS_CODE = 'materialsCode',
  MATERIALS_TITLE = 'materialsTitle',

  DIMENSIONS = 'dimensions',
  WEIGHT = 'weight',
  VOLUME = 'volume',

  DISCOUNT = 'discount',
  NET_PRICE = 'netPrice',
}
