<app-generic-modal [closable]="true" bodyClasses="p-0 bg-white d-flex flex-column"
  cssClasses="d-flex flex-column h-100" class="h-100">
  <ng-template appModalHeader>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col">
          <div class="d-flex align-items-center me-4 text-nowrap text-sm-wrap">
            <ng-scrollbar>
              <h1 class="m-0">{{ title }}</h1>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template appModalBody>
    <app-loader *ngIf="loading" class="mt-5" [loading]="loading" cssClasses="loader--relative"></app-loader>
    <div *ngIf="!loading" class="d-flex flex-column h-100">
      <div class="bg-white row g-0 border-bottom">
        <div class="d-block d-sm-none ps-4 pe-2" data-testid="mobileMenu" #mobileMenu>
          <ng-scrollbar>
            <div class="d-flex justify-content-between mb-3 pe-3">
              <div>
                <ng-container *ngTemplateOutlet="tabsNavigation; context: { $implicit: false }"></ng-container>
              </div>
              <div class="d-flex align-items-center">
                <ng-container *ngTemplateOutlet="shareButton; context: { $implicit: false }"></ng-container>
                <i
                  class="icon ni-info p-2 border-radius-0125r"
                  [class.bg-gray-400]="!mobileClaimInfoCollapsed"
                  (click)="this.onClaimInfoToggle()"
                  data-testid="mobileClaimInfoToggler"
                ></i>
              </div>
            </div>

            <div #collapse="ngbCollapse" [(ngbCollapse)]="mobileClaimInfoCollapsed">
              <ng-container *ngTemplateOutlet="claimInfo"></ng-container>
            </div>

            <app-claim-status class="d-inline-block mb-3" [status]="claim.status"></app-claim-status>
          </ng-scrollbar>
        </div>

        <div class="d-none d-sm-block col" data-testid="desktopMenu">
          <div class="d-flex justify-content-between px-2">
            <ng-container *ngTemplateOutlet="tabsNavigation; context: { $implicit: true }"></ng-container>

            <div class="d-flex align-items-center px-3 gap-4">
              <ng-container *ngTemplateOutlet="claimInfo"></ng-container>
              <app-claim-status [status]="claim.status"></app-claim-status>
              <ng-container *ngTemplateOutlet="shareButton; context: { $implicit: true }"></ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content position-relative pt-0 h-100 flex-grow overflow-auto">
        <ng-container [ngSwitch]="activeTab">
          <div class="pb-sm-4 mb-2" *ngSwitchCase="tabs.REPORT">
            <div *ngFor="let claimType of claim.claimTypes; let i = index; let last = last" class="w-100 text-grey bg-white">
              <div
                class="px-4 border-sm-bottom"
                [class.border-bottom]="!last"
                id="headingOne-{{ claimType.type }}"
              >
                <div class="d-flex align-items-center py-3 cursor-pointer mx-sm-4"
                  [class.border-bottom]="expandedTypes.includes(claimType.type)"
                  (click)="onTypeToggle(claimType.type)"
                >
                  <span class="h3 mb-0"
                    [class.color-gray-900]="expandedTypes.includes(claimType.type)"
                    [class.color-gray-600]="!expandedTypes.includes(claimType.type)">
                    {{ typesTranslationMap[claimType.type] | translate }}
                  </span>

                  <i class="ms-2 icon color-gray"
                    [ngClass]="{ 'ni-expand-less': expandedTypes.includes(claimType.type), 'ni-expand-more': !expandedTypes.includes(claimType.type) }"></i>
                </div>

                <div class="mx-sm-4" *ngFor="let claimTypeItem of claimType.claimTypeItems; let i = index; let last = last">
                  <div *ngIf="expandedTypes.includes(claimType.type)">
                    <div
                      class="d-block d-sm-none pt-3 pb-4"
                      [class.border-bottom]="countDifferentAttachmentTypes(claimType) > 0 || (claimType.claimTypeItems.length > 1 && !last)"
                      data-testid="mobileClaimItemContent"
                    >
                      <div *ngIf="claimTypeItem.productCode">
                        <label class="mb-3">
                          {{ 'INQUIRIES.CLAIMS.NEW.PRODUCT_CODE' | translate }} *
                        </label>
                        <div class="color-gray-900 fw-bold">{{ claimTypeItem.productCode }}</div>
                      </div>

                      <div *ngIf="claimItemQuantity(claimTypeItem)" class="pt-4">
                        <label class="mb-3">
                          {{ 'INQUIRIES.CLAIMS.NEW.QUANTITY' | translate }} *
                        </label>
                        <div class="color-gray-900 fw-bold">{{ claimItemQuantity(claimTypeItem) }}</div>
                      </div>

                      <div [class.pt-4]="claimTypeItem.productCode">
                        <label class="mb-3">
                          {{ 'INQUIRIES.CLAIMS.NEW.DESCRIPTION' | translate }} *
                        </label>
                        <p class="color-gray-900 mb-0 text-break--all">{{ claimTypeItem.description }}</p>
                      </div>
                    </div>

                    <div
                      class="d-none d-sm-flex py-2"
                      [class.border-bottom]="countDifferentAttachmentTypes(claimType) > 0"
                      data-testid="desktopClaimItemContent"
                    >
                      <div class="w-50">
                        <div class="d-flex pe-2">
                          <div class="py-2 pe-2" [class.w-50]="claimItemQuantity(claimTypeItem)"
                            [class.w-100]="!claimItemQuantity(claimTypeItem)">
                            <label class="mb-3" [for]="'productCode-' + i">
                              {{ 'INQUIRIES.CLAIMS.NEW.PRODUCT_CODE' | translate }} *
                            </label>
                            <div class="color-gray-900 fw-bold">{{ claimTypeItem.productCode }}</div>
                          </div>
                          <div *ngIf="claimItemQuantity(claimTypeItem)" class="py-2 ps-2 w-50">
                            <label class="mb-3" [for]="'quantity-' + i">
                              {{ 'INQUIRIES.CLAIMS.NEW.QUANTITY' | translate }} *
                            </label>
                            <div class="color-gray-900 fw-bold">{{ claimItemQuantity(claimTypeItem) }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="w-50 pt-2">
                        <div class="ps-1">
                          <label class="mb-3" [for]="'description-' + i">
                            {{ 'INQUIRIES.CLAIMS.NEW.DESCRIPTION' | translate }} *
                          </label>
                          <div class="d-flex gap-4">
                            <div class="flex-grow-1">
                              <p class="color-gray-900 mb-0 text-break--all">{{ claimTypeItem.description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="expandedTypes.includes(claimType.type) && countDifferentAttachmentTypes(claimType) > 0" class="d-sm-flex pb-2 p-sm-4">
                  <div
                    *ngIf="filterLeftPartAttachments(claimType) as leftPartAttachments"
                    [ngClass]="countDifferentAttachmentTypes(claimType) === 2 && !isMobile ? 'w-50' : 'w-100'">
                    <label class="form-label mb-3 mt-3 mt-sm-0">
                      {{ uploadedFileTypesTranslationMap[leftPartAttachments[0].type] | translate }}
                    </label>
                    <ng-scrollbar>
                      <div class="d-flex flex-sm-wrap gap-1 position-relative pb-2 pb-sm-0">
                        <ng-container
                          *ngFor="let attachment of leftPartAttachments"
                        >
                          <div class="d-inline-flex">
                            <app-file-preview [file]="attachment.file"></app-file-preview>
                          </div>
                        </ng-container>
                      </div>
                    </ng-scrollbar>
                  </div>

                  <div
                    *ngIf="countDifferentAttachmentTypes(claimType) === 2 && filterRightPartAttachments(claimType) as rightPartAttachments"
                    class="mt-3 mt-sm-0"
                    [ngClass]="!isMobile ? 'w-50' : 'w-100'"
                  >
                    <label class="form-label mb-3 w-100">
                      {{ uploadedFileTypesTranslationMap[rightPartAttachments[0].type] | translate }}
                    </label>
                    <ng-scrollbar>
                      <div class="d-flex flex-sm-wrap gap-1 position-relative pb-2 pb-sm-0">
                        <ng-container
                          *ngFor="let attachment of rightPartAttachments"
                        >
                          <div class="d-inline-flex">
                            <app-file-preview [file]="attachment.file"></app-file-preview>
                          </div>
                        </ng-container>
                      </div>
                    </ng-scrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white row p-4 mb-2 me-0" *ngSwitchCase="tabs.DELIVERY_INFORMATION">
            <div class="col-12 col-md-6 offset-md-3">
              <h3 class="mb-2" *ngIf="claim.address?.deliveryAddressee">{{ claim.address?.deliveryAddressee }}</h3>
              <p class="mb-0" *ngIf="fullDeliveryAddress">{{ fullDeliveryAddress }}</p>
              <p class="mb-0" *ngIf="claim.address?.contactWorkingHours">{{ claim.address?.contactWorkingHours }}
              </p>

              <h3 class="mb-2 mt-4">{{ 'ADDRESS.FORM.CONTACT_PERSON' | translate }}</h3>
              <p class="mb-0" *ngIf="claim.address?.contactPerson">{{ claim.address?.contactPerson }}</p>
              <p class="mb-0" *ngIf="claim.address?.email">{{ claim.address?.email }}</p>
              <p class="mb-0" *ngIf="claim.address?.phone">{{ claim.address?.phone }}</p>
            </div>
          </div>

          <app-claims-communication-tab *ngSwitchCase="tabs.COMMUNICATION"
            [claimId]="claim.id"></app-claims-communication-tab>

        </ng-container>
      </div>
    </div>
  </ng-template>
</app-generic-modal>

<div #filePreviewTemplate class="d-none">
  <div class="dz-preview dz-file-preview bg-gray-200 position-relative deep-hover-d-flex">
    <img class="preview-img" />
    <div class="position-absolute bottom-0 start-0 end-0 px-2 py-1 preview-file-name">
      <div class="dz-details text-break--all">
        <span data-dz-name></span>
      </div>
    </div>
  </div>
</div>

<ng-template #tabsNavigation let-showText>
  <ul class="nav nav-pills d-flex pe-sm-3 m-0 me-2">
    <li class="nav-item cursor-pointer" (click)="switchTab(tabs.REPORT)">
      <span class="nav-link full-bottom-border-width d-flex gap-1" [ngClass]="{'active': activeTab === tabs.REPORT}">
        <i class="icon ni-clipboard"></i>
        <span *ngIf="showText">{{ "INQUIRIES.CLAIMS.PREVIEW.TABS.REPORT" | translate }}</span>
      </span>
    </li>
    <li class="nav-item cursor-pointer" (click)="switchTab(tabs.DELIVERY_INFORMATION)" data-testid="deliveryInfoTabMenuItem">
      <span class="nav-link full-bottom-border-width d-flex gap-1" [ngClass]="{'active': activeTab === tabs.DELIVERY_INFORMATION}">
        <i class="icon ni-shipping"></i>
        <span *ngIf="showText">{{ "INQUIRIES.CLAIMS.PREVIEW.TABS.DELIVERY_INFORMATION" | translate }}</span>
      </span>
    </li>
    <li class="nav-item cursor-pointer" (click)="switchTab(tabs.COMMUNICATION)" data-testid="communicationTabMenuItem">
      <span class="nav-link full-bottom-border-width d-flex gap-1" [ngClass]="{'active': activeTab === tabs.COMMUNICATION}">
        <i class="icon ni-chat-square-plain"></i>
        <span *ngIf="showText">{{ "INQUIRIES.CLAIMS.PREVIEW.TABS.COMMUNICATION" | translate }}</span>
      </span>
    </li>
  </ul>
</ng-template>

<ng-template #shareButton let-lightTheme>
  <button
    class="d-flex btn me-2 px-2 no-outline"
    [class.btn-light]="lightTheme"
    [class.hover-bg-gray-200]="!lightTheme"
    (click)="onShare()"
    tooltipOpener
    [tooltipContent]="shareTooltipContent"
    additionalClasses="tooltip--black"
    [tooltipPosition]="isMobile ? 'left' : 'bottom'"
    [tooltipArrowPosition]="isMobile ? 'left' : 'top'"
    tooltipPosition="bottom"
    tooltipArrowPosition="top"
    [disabled]="!isOwnerOfClaim"
    data-testid="claimShareControl"
  >
    <i class="icon ni-share position-relative border-radius-0125r">
      <app-notification
        *ngIf="isOwnerOfClaim"
        [notifications]="claim.claimUsersCount"
        data-testid="claimSharesCount"
      >
      </app-notification>
    </i>
  </button>
</ng-template>

<ng-template #claimInfo>
  <div>{{ "INQUIRIES.CLAIMS.PREVIEW.TABS.SO" | translate }}: <span class="color-black fw-bold">{{
      claim.orderNum }}</span></div>
  <div *ngIf="claim.purchaseOrderNum" class="mt-3 mt-sm-0">{{ "INQUIRIES.CLAIMS.PREVIEW.TABS.PO" | translate }}: <span
      class="color-black fw-bold">{{
      claim.purchaseOrderNum }}</span></div>
  <div *ngIf="claim.jiraIssueKey" class="mt-3 mt-sm-0">{{ "INQUIRIES.CLAIMS.PREVIEW.TABS.ID" | translate }}: <span
      class="color-black fw-bold">{{
      claim.jiraIssueKey }}</span></div>

    <div class="d-block d-sm-none" [style.height.rem]="1"></div> <!-- spacer for smooth collapse -->
</ng-template>

<ng-template #shareTooltipContent>
  <div [style.max-width.px]="isMobile ? 100 : 200" [class.text-center]="isMobile">
    {{ (isOwnerOfClaim ? 'INQUIRIES.SHARE_INQUIRY.INFO_TOOLTIP' : 'INQUIRIES.SHARE_INQUIRY.SHARING_DISABLED_TOOLTIP') | translate }}
  </div>
</ng-template>
