import {Injectable} from '@angular/core';
import {RemoveRowInterface} from "../../../can-modify-product-view.component";
import {ExtraListElementRowInterface} from '../../order-articles-list/components/extra-row/extra-items.model';
import {UngroupEventInterface} from '../../order-articles-list/components/group/group.component';
import {OrderArticlesListRow, OrderArticlesListRowInterface} from '../../order-articles-list.interface';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderArticlesRowsService {
  private updateExtraRow: Subject<ExtraListElementRowInterface> = new Subject<ExtraListElementRowInterface>();
  private removeExtraRow: Subject<RemoveRowInterface[]> = new Subject<RemoveRowInterface[]>();
  private ungroup: Subject<UngroupEventInterface[]> = new Subject<UngroupEventInterface[]>();

  private applyCustomDiscount: Subject<OrderArticlesListRowInterface> = new Subject<OrderArticlesListRowInterface>();

  private rows: BehaviorSubject<OrderArticlesListRow[]> =
    new BehaviorSubject<OrderArticlesListRow[]>([]);

  private migrationIssuesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  getUpdateExtraRowAsObservable(): Observable<ExtraListElementRowInterface> {
    return this.updateExtraRow.asObservable();
  }

  setUpdateExtraRow(row: ExtraListElementRowInterface): void {
    this.updateExtraRow.next(row);
  }

  getRemoveExtraRowAsObservable(): Observable<RemoveRowInterface[]> {
    return this.removeExtraRow.asObservable();
  }

  setRemoveExtraRow(rows: RemoveRowInterface[]): void {
    this.removeExtraRow.next(rows);
  }

  getUngroupAsObservable(): Observable<UngroupEventInterface[]> {
    return this.ungroup.asObservable();
  }

  setUngroup(groups: UngroupEventInterface[]): void {
    this.ungroup.next(groups);
  }

  getApplyCustomDiscountAsObservable(): Observable<OrderArticlesListRowInterface> {
    return this.applyCustomDiscount.asObservable();
  }

  setApplyCustomDiscount(row: OrderArticlesListRowInterface): void {
    this.applyCustomDiscount.next(row);
  }

  getRowsAsObservable(): Observable<OrderArticlesListRow[]> {
    return this.rows.asObservable();
  }

  setRows(rows: OrderArticlesListRow[]): void {
    this.rows.next(rows);
  }

  getMigrationIssuesCountAsObservable() {
    return this.migrationIssuesCount$.asObservable();
  }

  setMigrationIssuesCount(count: number) {
    this.migrationIssuesCount$.next(count);
  }
}
