<div class="row" [formGroup]="form">
  <div class="col-6">
    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.MANAGER' | translate) + ':'"
      name="managerFullName"
      [formControl]="form.controls.fullName"
    >
    </app-text-field>
    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.EMAIL' | translate) + ':'"
      name="managerEmail"
      [formControl]="form.controls.email"
    >
    </app-text-field>
    <app-text-field
      class="document-template__field"
      [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
      [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.PHONE_NUMBER' | translate) + ':'"
      name="managerPhone"
      [formControl]="form.controls.phone"
    >
    </app-text-field>
  </div>
</div>
