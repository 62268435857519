import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, debounceTime, distinctUntilChanged, filter, map, retry, skip, startWith, switchMap } from 'rxjs';
import { PriceRequestItemFileInterface, PriceRequestItemInterface } from '../../../../core/models/price-request-item.model';
import { FormHelper } from '../../../../core/util/form-helper/form.helper';
import * as CustomValidators from '../../../../shared/class/custom-validators';
import { SharedModule } from '../../../../shared/shared.module';
import { CloseButtonComponent } from '../../../../ui-elements/close-button/close-button.component';
import { OkCancelModalComponent } from '../../../../ui-elements/generic-modal/ok-cancel-modal/ok-cancel-modal.component';
import { LoaderComponent } from '../../../../ui-elements/loader/loader.component';
import { OrderArticleMaterialInputComponent } from '../../../../ui-elements/order-article-material-input/order-article-material-input.component';
import { ToastService } from '../../../../ui-elements/toast/toast.service';
import { PriceRequestFilesInputComponent } from '../../price-request-files-input/price-request-files-input.component';
import { PriceRequestItemArticlesTableComponent } from '../../price-request-item-articles-table/price-request-item-articles-table.component';
import { PriceRequestItemsService } from '../../services/price-request-items.service';

@Component({
    selector: 'app-based-on-standard-price-request-item-form-modal',
    templateUrl: './based-on-standard-price-request-item-form-modal.component.html',
    styleUrls: ['./based-on-standard-price-request-item-form-modal.component.scss'],
    imports: [
      SharedModule,
      CloseButtonComponent,
      LoaderComponent,
      PriceRequestItemArticlesTableComponent,
      OrderArticleMaterialInputComponent,
      PriceRequestFilesInputComponent,
    ],
})
export class BasedOnStandardPriceRequestItemFormModalComponent implements OnInit, OnDestroy {
  @Input() item: PriceRequestItemInterface;
  @Input() priceRequestId: number;
  @Input() loading: boolean = false;
  @Input() editMode: boolean = true;
  @Output() saved = new EventEmitter<void>();
  @Output() discarded = new EventEmitter<void>();
  
  @HostListener('window:beforeunload', ['$event']) beforeUnload(e: BeforeUnloadEvent) {
    if (this.form.dirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  form: FormGroup = null;
  isUploadingFiles = false;
  isSaving = false;
  isDraft = false;
  atLeastOneFormControlHasValue = false;
  private subscriptions = new Subscription();

  constructor(
    private priceRequestItemsService: PriceRequestItemsService,
    private activeModal: NgbActiveModal,
    private translator: TranslateService,
    private toastService: ToastService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    if (!this.form && !this.loading) {
      this.constructForm();
      this.initializeSubscriptions();
    }
  }

  constructForm(fields?: PriceRequestItemInterface): void {
    this.isDraft = !fields?.isCompleted;
    
    this.form = new FormGroup({
      title: new FormControl<string>(fields?.title ?? '', [Validators.required, Validators.maxLength(255)]),
      quantity: new FormControl<number>(fields?.quantity, [Validators.required, Validators.min(1), CustomValidators.numbers]),
      description: new FormControl<string>(fields?.description ?? '', Validators.required),
      dimensions: new FormControl<string>(fields?.dimensions ?? '', Validators.maxLength(255)),
      finishes: new FormControl<String>(fields?.finishes, [Validators.required, CustomValidators.notEmpty('finishes')]),
      customMadePriceRequestItemFiles: new FormControl<PriceRequestItemFileInterface[]>(fields?.customMadePriceRequestItemFiles ?? []),
    });

    this.atLeastOneFormControlHasValue = FormHelper.formHasAtLeastOneControlWithValue(this.form);
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(
      this.form.valueChanges.subscribe((formValue: PriceRequestItemInterface) => {
        this.atLeastOneFormControlHasValue = FormHelper.formHasAtLeastOneControlWithValue(this.form);
        this.isUploadingFiles = !!formValue.customMadePriceRequestItemFiles.find(({ inProgress }) => inProgress);
      })
    );

    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          debounceTime(2000),
          startWith(this.form.getRawValue()),
          distinctUntilChanged((previous, current) => {
            return JSON.stringify(previous) === JSON.stringify(current);
          }),
          skip(1),
          filter(() => {
            // autosaves only if item is not being saved
            return !this.isSaving;
          }),
          switchMap((formValue) => {
            const itemCompleted = this.form.valid;
            return this.priceRequestItemsService
              .update(this.priceRequestId, this.item.id, formValue, this.form.valid)
              .pipe(map(() => itemCompleted));
          }),
          retry(1)
        )
        .subscribe((itemCompleted) => {
          let suffix;

          if (itemCompleted) {
            this.isDraft = false;
            suffix = 'SUCCESSFULLY_SAVED';
          } else {
            suffix = 'SUCCESSFULLY_SAVED_DRAFT';
          }

          this.translator.get(`INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.${suffix}`).subscribe((translation: string) => {
            this.toastService.success(translation);
          });
        })
    );
  }

  onSave(): void {
    this.form.updateValueAndValidity();

    this.isSaving = true;

    this.priceRequestItemsService.update(this.priceRequestId, this.item.id, this.form.getRawValue(), this.form.valid).subscribe({
      next: () => {
        let suffix;

        if (this.form.valid) {
          suffix = 'SUCCESSFULLY_SAVED';
        } else {
          suffix = 'SUCCESSFULLY_SAVED_DRAFT';
        }

        this.translator
        .get(`INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.${suffix}`)
        .subscribe((translation: string) => {
          this.toastService.success(translation);
          this.saved.emit();
          this.activeModal.dismiss();
        });
        
      },
      error: () => {
        this.isSaving = false;
      },
    });
  }

  onClose(): void {
    if (this.isSaving || this.isUploadingFiles) {
      return;
    }

    if (this.editMode) {
      this.priceRequestItemsService
        .update(this.priceRequestId, this.item.id, this.form.value, this.form.valid)
        .pipe(
          switchMap(() => {
            const suffix = this.form.valid ? 'SUCCESSFULLY_SAVED' : 'SUCCESSFULLY_SAVED_DRAFT';
            return this.translator.get(`INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.${suffix}`);
          })
        )
        .subscribe((translation: string) => {
          this.toastService.success(translation);
          this.saved.emit();
          this.activeModal.dismiss();
        });

      return;
    }

    this.translator
      .get([
        'ACTIONS.SAVE',
        'ACTIONS.DISCARD',
        'INQUIRIES.PRICE_REQUESTS.MODALS.SAVE_OR_DISCARD.HEADER',
        'INQUIRIES.PRICE_REQUESTS.MODALS.SAVE_OR_DISCARD.BODY',
      ])
      .subscribe((translations: { [key: string]: string }) => {
        const [save, discard, headerText, bodyText] = Object.values(translations);

        const modalRef = this.modalService.open(OkCancelModalComponent, {
          size: 'lg',
          centered: true,
        });

        const componentInstance = modalRef.componentInstance as OkCancelModalComponent;
        componentInstance.closeable = true;
        componentInstance.buttonTexts = [discard, save];
        componentInstance.buttonClasses = 'w-10r';

        componentInstance.bodyContent = bodyText;
        componentInstance.headerContent = headerText;

        componentInstance.cancel
          .pipe(
            switchMap(() => this.priceRequestItemsService.delete(this.priceRequestId, this.item.id)),
            switchMap(() => {
              const suffix = this.form.valid ? 'DISCARDED' : 'DRAFT_DISCARDED';
              return this.translator.get(`INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.${suffix}`);
            })
          )
          .subscribe((translation: string) => {
            this.toastService.success(translation);
            this.discarded.emit();
            this.activeModal.dismiss();
          });

        componentInstance.ok
          .pipe(
            switchMap(() => this.priceRequestItemsService.update(this.priceRequestId, this.item.id, this.form.value, this.form.valid)),
            switchMap(() => {
              const suffix = this.form.valid ? 'SUCCESSFULLY_SAVED' : 'SUCCESSFULLY_SAVED_DRAFT';
              return this.translator.get(`INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.${suffix}`);
            })
          )
          .subscribe((translation: string) => {
            this.toastService.success(translation);
            this.saved.emit();
            this.activeModal.dismiss();
          });
      });
  }


  onDiscard(): void {
    if (this.isSaving || this.isUploadingFiles) {
      return;
    }

    const suffix = this.isDraft ? 'DRAFT_DISCARDED' : 'DISCARDED';

    this.translator.get(`INQUIRIES.PRICE_REQUESTS.MODALS.BASED_ON_STANDARD.${suffix}`).subscribe((translation: string) => {
      this.priceRequestItemsService.delete(this.priceRequestId, this.item.id).subscribe(() => {
        this.toastService.success(translation);
        this.discarded.emit();
        this.activeModal.dismiss();
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
