<app-generic-modal
  [closable]="closeable"
  [mobileResponsiveFooter]="true"
  data-testid="okCancelModal"
>
  <ng-template appModalHeader>
    <h2 class="mb-0 modal-header__title">
      {{ headerContent }}
    </h2>
  </ng-template>
  <ng-template appModalBody>
    <p class="mb-0">
      {{ bodyContent }}
    </p>
  </ng-template>
  <ng-template appModalFooter>
    <button 
      *ngFor="let button of buttons; let index = index" 
      (click)="button.action()" class="btn" 
      [ngClass]="button.classes" 
      [attr.data-testid]="'okCancelModalButton' + index"
    >
      {{ buttonTexts[index] }}
    </button>
  </ng-template>
</app-generic-modal>
