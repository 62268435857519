<ng-container *ngIf="proposalForm || invoiceForm" [ngSwitch]="selectedTemplate.type">
  <ng-container *ngSwitchCase="documentTemplateType.PROPOSAL" [formGroup]="proposalForm">
    <div class="row" [formGroup]="proposalForm.controls.provider">
      <div class="col-6">
        <ng-template *ngTemplateOutlet="backgroundSelectBox"></ng-template>

        <app-text-field
          class="document-template__field"
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
          [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.PROVIDER' | translate) + ':'"
          name="providerName"
          [formControl]="proposalForm.controls.provider.controls.name"
        >
        </app-text-field>

        <app-text-field
          class="document-template__field"
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
          [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.ADDRESS' | translate) + ':'"
          name="providerAddress"
          [formControl]="proposalForm.controls.provider.controls.address"
        >
        </app-text-field>

        <app-text-field
          class="document-template__field"
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
          [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.COMPANY_CODE' | translate) + ':'"
          name="providerCompanyCode"
          [formControl]="proposalForm.controls.provider.controls.companyCode"
        >
        </app-text-field>

        <app-text-field
          class="document-template__field"
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
          [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.VAT_CODE' | translate) + ':'"
          name="providerVatCode"
          [formControl]="proposalForm.controls.provider.controls.vatCode"
        >
        </app-text-field>

        <app-text-field
          class="document-template__field"
          [class.vat-enabled]="!!selectedTemplate.isVat"
          [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
          [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.VAT_RATE' | translate) + ':'"
          name="providerVatRate"
          [formControl]="proposalForm.controls.provider.controls.vatRate"
          [required]="!!selectedTemplate.isVat"
        >
        </app-text-field>
      </div>
      <div class="col-6">
        <ng-container
          *ngTemplateOutlet="
            uploadLogo;
            context: { placeholderText: 'CREATE_DOCUMENT.TEMPLATE.SUPPLIER_LOGO', logo: selectedTemplate.dealer.logo, prop: 'dealer' }
          "
        ></ng-container>
      </div>
    </div>
    <hr />
    <ng-container [formGroup]="proposalForm.controls.customer">
      <div class="row">
        <div class="col-6 mb-3">
          <app-ui-elements-form-drop-down
            [values]="customerDataTypes"
            [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.HEADLINE.CLIENT_DATA.TITLE' | translate"
            [selectedValue]="data.clientDataType"
            labelField="title"
            valueField="id"
            (onChange)="onCustomerDataTypeSelect($event.id)"
          ></app-ui-elements-form-drop-down>

          <app-text-field
            class="document-template__field"
            [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
            [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.CUSTOMER' | translate) + ':'"
            name="customerName"
            [formControl]="proposalForm.controls.customer.controls.name"
          >
          </app-text-field>

          <app-text-field
            class="document-template__field"
            [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
            [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.ADDRESS' | translate) + ':'"
            name="customerAddress"
            [formControl]="proposalForm.controls.customer.controls.address"
          >
          </app-text-field>

          <app-text-field
            class="document-template__field"
            [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
            [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.TITLE' | translate) + ':'"
            name="customerTitle"
            [formControl]="proposalForm.controls.customer.controls.title"
          >
          </app-text-field>

          <app-date-input
            [label]="'CREATE_DOCUMENT.TEMPLATE.FORM.PROPOSAL_DATE' | translate"
            [formControl]="proposalForm.controls.customer.controls.proposalDate"
            id="customerProposalDate"
          ></app-date-input>
        </div>
        <div class="col-6">
          <ng-container
            *ngTemplateOutlet="
              uploadLogo;
              context: {
                placeholderText: 'CREATE_DOCUMENT.TEMPLATE.CLIENT_LOGO',
                logo: data.client.clientLogo,
                prop: 'client',
                forceWhiteBg: true
              }
            "
          ></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="row" *ngSwitchCase="documentTemplateType.INVOICE" [formGroup]="invoiceForm">
    <div class="col-6">
      <ng-template *ngTemplateOutlet="backgroundSelectBox"></ng-template>

      <app-text-field
        class="document-template__field"
        [theme]="[textFieldThemeTypes.INLINE, textFieldThemeTypes.NEW]"
        [text]="('CREATE_DOCUMENT.TEMPLATE.FORM.INVOICE_NUMBER' | translate) + ':'"
        name="invoiceNumber"
        [formControl]="invoiceForm.controls.invoiceNumber"
      >
      </app-text-field>

      <app-date-input
        [label]="'CREATE_DOCUMENT.TEMPLATE.FORM.INVOICE_DATE' | translate"
        [formControl]="invoiceForm.controls.invoiceDate"
        id="invoiceDate"
      ></app-date-input>
    </div>
    <div class="col-6">
      <ng-container
        *ngTemplateOutlet="
          uploadLogo;
          context: { placeholderText: 'CREATE_DOCUMENT.TEMPLATE.SUPPLIER_LOGO', logo: selectedTemplate.dealer.logo, prop: 'dealer' }
        "
      ></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #uploadLogo let-placeholderText="placeholderText" let-logo="logo" let-prop="prop" let-forceWhiteBg="forceWhiteBg">
  <div class="d-flex justify-content-center h-100 align-items-center pb-3">
    <app-file-upload
      class="document-template__image"
      imgClass="img-fluid"
      [labels]="{
        empty: 'CREATE_DOCUMENT.TEMPLATE.FORM.UPLOAD' | translate,
        selected: 'CREATE_DOCUMENT.TEMPLATE.FORM.UPLOAD' | translate
      }"
      [fileUrl]="logo?.url || logo?.file"
      [removable]="!!(logo?.url || logo?.file)"
      (selected)="onLogoUpload($event, prop)"
      (remove)="onLogoRemove(prop)"
    >
      <ng-container placeholder>
        <div
          class="logo-placeholder"
          [ngClass]="selectedTemplate.backgroundType === headlineBackgroundType.DARK ? 'logo-placeholder--dark' : 'logo-placeholder--light'"
          [class.bg-white]="forceWhiteBg"
        >
          <span
            class="logo-placeholder__block"
            [class.bg-gray-600]="forceWhiteBg"
            [class.color-white]="forceWhiteBg"
            *ngIf="!logo?.url && !logo?.file"
          >
            {{ placeholderText | translate }}
          </span>
          <span class="logo-placeholder__block uploaded" *ngIf="logo?.url">
            <img [src]="logo?.url" alt="" class="img-fluid">
          </span>
        </div>
      </ng-container>
    </app-file-upload>
  </div>
</ng-template>

<ng-template #backgroundSelectBox>
  <app-ui-elements-form-drop-down
    [values]="backgroundType"
    [title]="'CREATE_DOCUMENT.TEMPLATE.FORM.HEADLINE.BACKGROUND.TITLE' | translate"
    [selectedValue]="selectedTemplate.backgroundType"
    labelField="title"
    valueField="id"
    (onChange)="onBackgroundTypeChange($event.id)"
  ></app-ui-elements-form-drop-down>
</ng-template>
