import { Inject, Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { AuthService } from '../../../auth/auth.service';
import { StoreHandlerService } from '../../models/store-handler-service.model';
import { PriceListConstants } from './price-list.enum';
import { CAN_CHANGE_PRICELIST } from '../../../permissions.config';
import { PermissionsDecisionMakerServiceInterface } from '../../../permissions/decision-maker.service';
import { PriceListInterface } from '../../models/pricelist.model';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { Params } from '@angular/router';
import { SaleMode } from '../../../shared/components/list-mode-switch/sale-mode.types';
import { map } from 'rxjs/operators';
import { PERMISSIONS_DECISION_MAKER_SERVICE } from '../../../permissions/injection-tokens';

export interface CombinedParamsObservableInterface {
  params: Params;
  saleMode: SaleMode;
}

export function combinedRouteSaleModeObservables(
  paramsObservable: Observable<any>,
  saleModeObservable: Observable<SaleMode>,
  priceListObservable?: Observable<PriceListInterface>
): Observable<CombinedParamsObservableInterface> {
  const observables = [paramsObservable, saleModeObservable];
  if (priceListObservable) {
    observables.push(priceListObservable); // used only to trigger price list changes, because price-list is set to interceptor
  }
  return combineLatest(observables).pipe(
    map(
      ([params, saleMode]): CombinedParamsObservableInterface => {
        return { params, saleMode };
      }
    )
  );
}

@Injectable({
  providedIn: 'root',
})
export class PriceListStoreHandlerService extends StoreHandlerService {
  protected values$: ReplaySubject<PriceListInterface[]> = new ReplaySubject<PriceListInterface[]>(1);
  protected currentValue$: ReplaySubject<PriceListInterface> = new ReplaySubject<PriceListInterface>(1);
  protected currentValue: PriceListInterface;

  constructor(
    protected api: ApiService,
    private authService: AuthService,
    @Inject(PERMISSIONS_DECISION_MAKER_SERVICE)
    private permissionsDecisionMakerService: PermissionsDecisionMakerServiceInterface
  ) {
    super(api, 'price-list/', PriceListConstants.SESSION_STORAGE_KEY);
  }

  init() {
    this.permissionsDecisionMakerService.shouldAllow([CAN_CHANGE_PRICELIST]).subscribe((shouldAllow: boolean) => {
      if (shouldAllow) {
        this.setCurrentValue(this.getFromStorage());
        this.fetch().subscribe(priceLists => {
          this.values$.next(priceLists);
          if (!this.getFromStorage()) {
            this.setCurrentValue(this.authService.getUser().priceList);
          }
        });
      } else {
        // If not allowed to change price-list then get price-list from in local storage saved user
        this.clearSessionStorage();
        this.setCurrentValue(this.authService.getUser().priceList);
      }
    });
  }

  /**
   * Change price list and after successful changes fire pricelist event outside
   * @param {PriceListInterface} priceList
   */
  switch(priceList: PriceListInterface, reload: boolean = false) {
    if ((this.getCurrentValue() && this.getCurrentValue().id !== priceList.id) || !this.getCurrentValue()) {
      const previousValue = this.currentValue;
      this.send(priceList).subscribe(
        () => {
          this.setCurrentValue(priceList);
          this.saveToStorage(priceList);

          if (reload) {
            window.location.reload();
          }
        },
        () => this.setCurrentValue(previousValue) // on error switch back to previous pricelist
      );
    }
  }

  protected send(priceList: PriceListInterface): Observable<any> {
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
      return of(null);
    }
    const body = {
      pricelist: priceList.id,
    };
    return this.api.post('price-list/', body);
  }
}
