import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ButtonInterface} from '../../../shared/interfaces/modal/button.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ok-cancel-modal',
  templateUrl: './ok-cancel-modal.component.html',
})
export class OkCancelModalComponent implements OnInit, OnChanges {
  @Input() buttonTexts: string[] = [];
  @Input() bodyContent: string;
  @Input() headerContent: string;
  @Input() closeable: boolean = false;
  @Input() buttonClasses: string = '';

  @Output() ok: EventEmitter<null> = new EventEmitter<null>();
  @Output() cancel: EventEmitter<null> = new EventEmitter();

  buttons: ButtonInterface[];

  constructor(protected activeModal: NgbActiveModal) {
  }

  ngOnChanges() {
  }

  ngOnInit() {
    this.buttons = [
      {
        text: null,
        action: () => this.onCancel(),
        classes: `btn btn-secondary btn-lg rounded-0 px-5 ${this.buttonClasses}`
      },
      {
        text: null,
        action: () => this.onOk(),
        classes: `btn btn-lg btn-primary rounded-0 ${this.buttonClasses}`
      }
    ];
  }

  onOk() {
    this.activeModal.close();
    this.ok.emit();
  }

  onCancel() {
    this.activeModal.close();
    this.cancel.emit();
  }
}
