import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, forkJoin } from 'rxjs';
import { FileData } from '../../../../core/models/file-data.model';
import {
  PriceRequestItemFileInterface,
  PriceRequestItemFileTypeEnum,
  PriceRequestItemInterface,
  PriceRequestItemTypeEnum,
} from '../../../../core/models/price-request-item.model';
import { RowImageComponent } from '../../../../shared/components/row-image/row-image.component';
import { ClickStopPropagationDirective } from '../../../../shared/directives/click-stop-propagation/click-stop-propagation.directive';
import { SharedModule } from '../../../../shared/shared.module';
import { OrderArticleMaterialInputComponent } from '../../../../ui-elements/order-article-material-input/order-article-material-input.component';
import { PriceRequestFilesInputComponent } from '../../price-request-files-input/price-request-files-input.component';
import { PriceRequestItemsService } from '../../services/price-request-items.service';

interface ItemFormInterface {
  clarificationAnswer: FormControl<string>;
  attachments: FormControl<PriceRequestItemFileInterface[]>;
}

interface ItemFormValueInterface {
  clarificationAnswer: string;
  attachments: PriceRequestItemFileInterface[];
}

interface FileToDeleteInterface {
  priceRequestId: number;
  priceRequestItemId: number;
  fileId: number;
}

@Component({
    selector: 'app-clarification-modal',
    templateUrl: './clarification-modal.component.html',
    styleUrls: ['./clarification-modal.component.scss'],
    imports: [
      SharedModule,
      RowImageComponent,
      OrderArticleMaterialInputComponent,
      ClickStopPropagationDirective,
      PriceRequestFilesInputComponent,
    ],
})
export class ClarificationModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(PriceRequestFilesInputComponent) filesInputComponents: QueryList<PriceRequestFilesInputComponent>;

  @Input() priceRequestItems: PriceRequestItemInterface[];
  @Input() openedFromOffer? = false;
  @Output() closed = new EventEmitter<boolean>();
  @Output() submitted = new EventEmitter<void>();

  isUploadingFiles = false;
  isSubmitting = false;
  isClosing = false;
  imageFiles: (FileData | undefined)[] = [];
  form: FormGroup;

  fileTypes = PriceRequestItemFileTypeEnum;

  private initialClarificationFiles: FileToDeleteInterface[] = [];
  private subscriptions = new Subscription();

  constructor(private priceRequestItemsService: PriceRequestItemsService, private activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      items: this.fb.array(this.priceRequestItems.map((item) => this.createItemFormGroup(item))),
    });

    this.subscriptions.add(
      this.form.get('items').valueChanges.subscribe((items: ItemFormValueInterface[]) => {
        this.isUploadingFiles = items.some((item) => item.attachments.some((file) => file.inProgress));
      })
    );
  }

  ngAfterViewInit(): void {
    this.filesInputComponents.forEach((component) => {
      this.subscriptions.add(
        component.fileDelete.subscribe((deletedFileId) => {
          this.initialClarificationFiles = this.initialClarificationFiles.filter((file) => file.fileId !== deletedFileId);
        })
      );
    });
  }

  private createItemFormGroup(item: PriceRequestItemInterface): FormGroup {
    const clarificationFiles = item.customMadePriceRequestItemFiles.filter(
      (file) => file.type === PriceRequestItemFileTypeEnum.CLARIFICATION
    );

    clarificationFiles.forEach((file) => {
      this.initialClarificationFiles.push({
        priceRequestId: item.customMadePriceRequestId,
        priceRequestItemId: item.id,
        fileId: file.id,
      });
    });

    this.imageFiles.push(this.priceRequestItemsService.getFirstImageFile(item));

    return this.fb.group({
      clarificationAnswer: new FormControl<string>('', Validators.required),
      attachments: new FormControl<PriceRequestItemFileInterface[]>([...clarificationFiles]),
    });
  }

  onReviewItem(item: PriceRequestItemInterface): void {
    this.priceRequestItemsService.openPriceRequestItemModal(item);
  }

  onClose(): void {
    if (this.isSubmitting || this.isUploadingFiles || this.isClosing) {
      return;
    }

    this.isClosing = true;

    let filesToDelete: FileToDeleteInterface[] = [...this.initialClarificationFiles];

    this.filesInputComponents.forEach((component) => {
      component.uploadedFilesIds.forEach((fileId) => {
        filesToDelete.push({
          priceRequestId: component.priceRequestId,
          priceRequestItemId: component.priceRequestItemId,
          fileId,
        });
      });
    });

    if (!filesToDelete.length) {
      this.closed.emit(false);
      this.activeModal.dismiss();

      return;
    }

    const shouldRefresh = !!this.initialClarificationFiles.length;

    const requests = filesToDelete.map((file) =>
      this.priceRequestItemsService.deleteFile(file.priceRequestId, file.priceRequestItemId, file.fileId)
    );

    forkJoin(requests).subscribe({
      next: () => {
        this.closed.emit(shouldRefresh);
        this.activeModal.dismiss();
      },
      error: () => {
        this.closed.emit(true);
        this.activeModal.dismiss();
      },
    });
  }

  onSubmit(): void {
    this.isSubmitting = true;

    const items = this.form.get('items') as FormArray;

    const submissionRequests = items.controls.map((group: FormGroup<ItemFormInterface>, index) => {
      const item = this.priceRequestItems[index];

      return this.priceRequestItemsService.submitClarification(item.customMadePriceRequestId, item.id, group.value.clarificationAnswer);
    });

    forkJoin(submissionRequests).subscribe({
      next: () => {
        this.submitted.emit();
        this.activeModal.dismiss();
      },
      error: () => {
        this.closed.emit(true); // assures that list will be refreshed
        this.activeModal.dismiss();
      },
    });
  }

  getItemFormGroup(index: number): FormGroup<ItemFormInterface> {
    const itemsArrayControl = this.form.get('items') as FormArray;

    return itemsArrayControl.at(index) as FormGroup<ItemFormInterface>;
  }

  getItemIcon(item: PriceRequestItemInterface): string {
    return item.type === PriceRequestItemTypeEnum.BASED_ON_STANDARD ? 'ni-widgets' : 'ni-widgets-fill';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
