<div class="text-nowrap d-sm-flex align-items-center m-0 py-2" [class.col-8]="!inModal">
  <label class="w-50 pe-3 m-0 mb-2 mb-sm-0 color-gray-500">{{ 'SETTINGS.BASICS.EMAIL' | translate }}:</label>
  <input class="col-12 col-sm-6 form-control" value="{{ user?.email }}" autocomplete="email" disabled data-testid="emailInput"/>
</div>

<ng-template #inputWrapper let-item>
  <div class="text-nowrap d-sm-flex align-items-center m-0 py-2" [class.col-8]="!inModal">
    <label class="w-50 pe-3 m-0 mb-2 mb-sm-0 color-gray-500">
      {{ item.labelKey | translate }}:<span *ngIf="item.input | isFormControlRequired">*</span>
    </label>
    <ng-container *ngTemplateOutlet="item.field, context: { $implicit: item.input }"></ng-container>
  </div>
  <div class="text-danger mb-2 small px-0" 
    *ngIf="item.input.dirty && item.input.invalid"
    [ngClass]="{ 'offset-sm-4': !inModal, 'offset-sm-6': inModal }"
  >
    {{ item.input.errors | translateValidationErrors }}
  </div>
</ng-template>

<ng-template #textInput let-control><input class="col-12 col-sm-6 form-control" [formControl]="control" data-testid="textInput"/></ng-template>
<ng-template #countrySelect let-control>
  <select class="col-12 col-sm-6 form-control form-select" name="country" [formControl]="control">
    <option [ngValue]="null" disabled>{{ 'ACTIONS.SELECT_OPTION' | translate }}</option>
    <option *ngFor="let country of countries" [ngValue]="country.id">{{ country.title || country.code }}</option>
  </select>
</ng-template>

<ng-container *ngTemplateOutlet="inputWrapper; context: {$implicit: {labelKey: 'SETTINGS.PROFILE.FIRST_NAME', input: profileForm.get('firstName'), field: textInput}}"></ng-container>
<ng-container *ngTemplateOutlet="inputWrapper; context: {$implicit: {labelKey: 'SETTINGS.PROFILE.LAST_NAME', input: profileForm.get('lastName'), field: textInput}}"></ng-container>
<ng-container *ngTemplateOutlet="inputWrapper; context: {$implicit: {labelKey: 'SETTINGS.PROFILE.PHONE', input: profileForm.get('phone'), field: textInput}}"></ng-container>
<ng-container *ngTemplateOutlet="inputWrapper; context: {$implicit: {labelKey: 'SETTINGS.PROFILE.COMPANY_NAME', input: profileForm.get('companyName'), field: textInput}}"></ng-container>
<ng-container *ngTemplateOutlet="inputWrapper; context: {$implicit: {labelKey: 'SETTINGS.PROFILE.ADDRESS', input: profileForm.get('address'), field: textInput}}"></ng-container>
<ng-container *ngTemplateOutlet="inputWrapper; context: {$implicit: {labelKey: 'SETTINGS.PROFILE.CITY', input: profileForm.get('city'), field: textInput}}"></ng-container>
<ng-container *ngTemplateOutlet="inputWrapper; context: {$implicit: {labelKey: 'SETTINGS.PROFILE.POSTAL_CODE', input: profileForm.get('postCode'), field: textInput}}"></ng-container>
