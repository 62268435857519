import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BREAKPOINTS } from '../../core/constants/breakpoints.contants';
import { ClaimStatus } from '../../core/enums/claim-state.enum';
import {
  ClaimFileType,
  ClaimFileTypeTranslationMap,
  ClaimInterface,
  ClaimType,
  ClaimTypeFile,
  ClaimTypeItemInterfaceType,
  ClaimTypeItemType,
  ClaimTypeTranslationMap,
  ClaimTypeWithFiles,
} from '../../core/models/claim.model';
import { imageFileExtension } from '../../core/models/file-data.model';
import { UserService } from '../../core/services/user/user.service';
import { ClaimsService } from '../claims.service';
import { ShareInquiryModalComponent } from '../share-inquiry-modal/share-inquiry-modal.component';

export enum ClaimPreviewTabs {
  REPORT = 'report',
  DELIVERY_INFORMATION = 'delivery',
  COMMUNICATION = 'communication'
}

@Component({
  selector: 'app-claim-preview',
  templateUrl: './claim-preview.component.html',
  styleUrls: ['./../claim-modals.base.scss', './claim-preview.component.scss'],
})

export class ClaimPreviewComponent implements AfterViewInit, OnDestroy {
  @Input() id: number;
  @Input() title: string;
  @Input() activeTab: ClaimPreviewTabs = ClaimPreviewTabs.REPORT;

  @ViewChild('mobileMenu') mobileMenu: ElementRef;

  claim: ClaimInterface;
  tabs = ClaimPreviewTabs;
  typesTranslationMap = ClaimTypeTranslationMap;
  uploadedFileTypesTranslationMap = ClaimFileTypeTranslationMap;
  expandedTypes: ClaimType[] = [];
  claimFileTypes = ClaimFileType;
  activeUserId: number;
  loading = true;
  isMobile = false;
  mobileClaimInfoCollapsed = true;
  private mobileResizeHandler: () => void | null = null;

  constructor(
    private claimService: ClaimsService,
    private modalService: NgbModal,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
  ) {

  }

  ngOnInit() {
    this.claimService.getOne(this.id).noCache().subscribe((claim) => {
      this.claim = claim;

      this.expandedTypes = [...this.claim.claimTypes.map((claimType) => claimType.type)];

      if ([ClaimStatus.WAITING, ClaimStatus.COMPLETED].includes(this.claim.status)) {
        this.activeTab = ClaimPreviewTabs.COMMUNICATION;
      }

      this.loading = false;
    });

    this.userService.fromStorage().subscribe((user) => {
      this.activeUserId = user.id;
    });

    this.breakpointObserver.observe(`(max-width: ${BREAKPOINTS.SM})`).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngAfterViewInit(): void {
    if (this.isMobile && window.visualViewport) {
      let previousHeight = window.visualViewport.height;
      let previousWidth = window.visualViewport.width;

      this.mobileResizeHandler = () => {
        if (this.activeTab !== this.tabs.COMMUNICATION) {
          return;
        }

        const currentHeight = window.visualViewport.height;
        const currentWidth = window.visualViewport.width;
        const virtualKeyboardHeightThreshold = 100;

        /** visualViewport resize event fires on both virtual keyboard and zoom events
         *  so this if checks whether resize was triggered by appearance/disappearance of virtual keyboard
         */
        if (currentWidth === previousWidth && Math.abs(currentHeight - previousHeight) > virtualKeyboardHeightThreshold) {
          this.recalculateMobileMessagesContainerHeight();

          previousHeight = currentHeight;
          previousWidth = currentWidth;
        }
      };

      window.visualViewport.addEventListener('resize', this.mobileResizeHandler);
    }
  }

  recalculateMobileMessagesContainerHeight() {
    const messagesContainerElement = document.querySelector('#messagesContainer') as HTMLElement;
    const headerElement = document.querySelector('.modal-header') as HTMLElement;
    const messageFieldElement = document.querySelector('#communicationMessageField') as HTMLElement;

    if (!messagesContainerElement || !headerElement || !messageFieldElement) {
      return;
    }
    
    const viewportHeight = window.visualViewport.height;
    const modalHeaderHeight = headerElement.offsetHeight;
    const mobileMenuHeight = this.mobileMenu.nativeElement.offsetHeight;
    const messageFieldHeight = messageFieldElement.offsetHeight;
    
    const calculatedMessagesContainerHeight = viewportHeight - modalHeaderHeight - mobileMenuHeight - messageFieldHeight;
    
    messagesContainerElement.classList.remove('flex-grow-1');
    messagesContainerElement.style.height = `${Math.max(calculatedMessagesContainerHeight, 0)}px`;

    setTimeout(() => {
      window.scrollTo(0, 0); //needed for iOS
    }, 100);
  }

  ngOnDestroy() {
    if (this.mobileResizeHandler) {
      window.visualViewport.removeEventListener('resize', this.mobileResizeHandler);
    }
  }

  public switchTab(tab) {
    this.activeTab = tab;
  }

  get fullDeliveryAddress() {
    const addressData = [];
    const { address } = this.claim;

    if (!address) {
      return null;
    }

    if (address.address) {
      addressData.push(address.address);
    }

    if (address.postCode) {
      addressData.push(`${address.postCode} ${address.city ? address.city : ''}`);
    }

    if (address.country?.title) {
      addressData.push(address.country.title);
    } else if (address.country?.code) {
      addressData.push(address.country.code);
    }

    return addressData.join(', ');
  }

  onClaimInfoToggle(): void {
    this.mobileClaimInfoCollapsed = !this.mobileClaimInfoCollapsed;

    if (this.activeTab === this.tabs.COMMUNICATION) {
      const messagesContainerElement = document.querySelector('#messagesContainer') as HTMLElement;
      messagesContainerElement.classList.add('flex-grow-1');  
    }
  }

  onTypeToggle(type: ClaimType) {
    if (this.expandedTypes.includes(type)) {
      this.expandedTypes = this.expandedTypes.filter(t => t !== type);

      return;
    }

    this.expandedTypes.push(type);
  }

  countDifferentAttachmentTypes(claimType: ClaimTypeItemType): number {
    const fileList: ClaimTypeFile[] = (claimType as ClaimTypeWithFiles).claimTypeFiles || [];

    return [...new Set(fileList.map(file => file.type) || [])].length;
  }

  filterLeftPartAttachments(claimType: ClaimTypeItemType) {
    const fileList: ClaimTypeFile[] = (claimType as ClaimTypeWithFiles).claimTypeFiles || [];

    return this.filterAttachments(fileList, [ClaimFileType.ATTACHMENT, ClaimFileType.DAMAGE, ClaimFileType.ITEM_PICTURE, ClaimFileType.PROBLEM_PICTURE]);
  }

  filterRightPartAttachments(claimType: ClaimTypeItemType) {
    const fileList: ClaimTypeFile[] = (claimType as ClaimTypeWithFiles).claimTypeFiles || [];

    return this.filterAttachments(fileList, [ClaimFileType.CMR_DOCUMENT, ClaimFileType.PRODUCT_LABEL_PICTURE]);
  }

  filterAttachments(fileList: ClaimTypeFile[], type: ClaimFileType | ClaimFileType[]) {
    return fileList.filter(file => file.type === type || type?.includes(file.type));
  }

  isImageFile(fileName: string): boolean {
    // @todo: refactor this when mime type information will be available on the API side
    const fileNameParts = fileName.split('.');

    return imageFileExtension.includes(fileNameParts[fileNameParts.length - 1]);
  }

  claimItemQuantity(item: ClaimTypeItemInterfaceType): number | undefined {
    return 'quantity' in item ? item.quantity as number : undefined;
  }

  onShare(): void {
    const modalRef = this.modalService.open(ShareInquiryModalComponent, {
      size: 'lg',
      centered: true,
      scrollable: true,
      modalDialogClass: 'share-inquiry-modal',
    });

    const componentInstance = modalRef.componentInstance as ShareInquiryModalComponent;
    componentInstance.inquiryId = this.claim.id;
    componentInstance.inquiryService = this.claimService;

    componentInstance.sharedWithCount.subscribe((count) => {
      this.claim.claimUsersCount = count;
    });
  }

  get isOwnerOfClaim(): boolean {
    return this.activeUserId === this.claim.userId;
  }
}
