<app-generic-modal 
  [closable]="true" 
  cssClasses="d-flex d-sm-block flex-column mh-75vh mh-sm-auto"
  bodyClasses="p-0" 
  mobileBodyClasses="d-flex flex-column p-0"
>
  <ng-template appModalHeader>
    <h2 class="mb-0 me-4 text-break--all">{{image.filename}}</h2>
  </ng-template>
  
  <ng-template appMobileModalBody>
    <div class="d-flex flex-column flex-grow-1">
      <div
      class="mobile-image flex-grow-1 bg-gray-200"
      [style.background-image]="'url(' + ((image.url | encodeUri | uncache | async) ?? '') + ')'"
      ></div>

      <a 
        class="btn btn-secondary border-top p-3 no-outline" 
        [href]="image.url" 
        appClickStopPropagation 
        download
      >
        <i class="icon ni-download"></i>
        {{ 'GALLERY.DOWNLOAD' | translate }}
      </a>
    </div>
  </ng-template>

  <ng-template appModalBody>
    <app-gallery [images]="[image]" [noDownloadAll]="true"></app-gallery>
  </ng-template>
</app-generic-modal>
