import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { environment } from '../../../../environments/environment';
import { FileSizeEnum } from '../../../api.service';
import { AuthService } from '../../../auth/auth.service';
import { FileUploadErrorEnum } from '../../../core/enums/file-upload-error.enum';
import { ImpersonateService } from '../../../core/impersonate/impersonate.service';
import { DropzoneFileInterface, DropzoneFileListInterface } from '../../../core/models/file.model';
import { PriceRequestItemFileInterface, PriceRequestItemFileTypeEnum } from '../../../core/models/price-request-item.model';
import { FileSizeHelper } from '../../../core/util/file-size.helper';
import { FilesInputBaseComponent } from '../../../shared/components/base/files-input-base/files-input-base.component';
import { DropzoneFileUploadErrorResponseInterface, ErrorService } from '../../../shared/services/error/error.service';
import { SharedModule } from '../../../shared/shared.module';
import { FilePreviewComponent } from '../../../ui-elements/file-preview/file-preview.component';
import { ToastService } from '../../../ui-elements/toast/toast.service';
import { PriceRequestItemFileUploadResponseInterface, PriceRequestItemsService } from '../services/price-request-items.service';

export const PRICE_REQUEST_FILES_INPUT_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PriceRequestFilesInputComponent),
  multi: true,
};

@Component({
    selector: 'app-price-request-files-input',
    templateUrl: './price-request-files-input.component.html',
    styleUrls: ['./price-request-files-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [PRICE_REQUEST_FILES_INPUT_ACCESSOR],
    imports: [SharedModule, NgClickOutsideDirective, DropzoneModule, FilePreviewComponent, SvgIconComponent]
})
export class PriceRequestFilesInputComponent extends FilesInputBaseComponent implements OnInit, OnChanges {
  public model: PriceRequestItemFileInterface[] = [];

  @Input() priceRequestId: number;
  @Input() priceRequestItemId: number;
  @Input() dropZoneName: string;
  @Input() maxFileSize: FileSizeEnum = FileSizeEnum.SIZE_128MB;
  @Input() fileType: PriceRequestItemFileTypeEnum = PriceRequestItemFileTypeEnum.GENERAL;
  @Output() fileDelete = new EventEmitter<number>();

  config: DropzoneConfigInterface | null;
  isDisabled = false;
  deletedFilesIds: number[] = [];
  uploadedFilesIds: number[] = [];
  draggingOverDropzone = false;
  maxFileSizeInGB = 1;
  maxNumberOfParallelUploads = 20;

  FileUploadErrors = FileUploadErrorEnum;

  constructor(
    private translator: TranslateService,
    private toastService: ToastService,
    private authService: AuthService,
    private impersonateService: ImpersonateService,
    private priceRequestItemsService: PriceRequestItemsService,
    private zone: NgZone,
    private errorService: ErrorService
  ) {
    super();
  }

  ngOnInit() {
    this.isDisabled = !this.priceRequestId;

    if (!this.isDisabled) {
      this.setupDropZone(this.priceRequestId, this.priceRequestItemId);
    }
  }

  ngOnChanges() {
    this.isDisabled = !this.priceRequestId;

    if (!this.isDisabled && !this.config) {
      this.setupDropZone(this.priceRequestId, this.priceRequestItemId);
    }
  }

  setupDropZone(priceRequestId: number, priceRequestItemId: number) {
    const component = this;
    const url = new URL(`${environment.api}custom-made-price-requests/${priceRequestId}/items/${priceRequestItemId}/files/${this.fileType}`);

    if (this.impersonateService.impersonated()) {
      const impersonatedUser = this.impersonateService.getUser();

      url.searchParams.append(
        '_impersonate',
        impersonatedUser.email
      );
    }

    this.translator.get('ACTIONS.UPLOAD').subscribe((translation: string) => {
      this.config = {
        url: url.toString(),
        acceptedFiles: this.acceptedFiles,
        parallelUploads: this.maxNumberOfParallelUploads,
        // According to the Dropzone.js documentation, the maximum file size should be specified in bytes.
        // However, in practice, the specified value is interpreted as megabytes.
        maxFilesize: FileSizeHelper.convertGigabytesToMegabytes(component.maxFileSizeInGB),
        disablePreviews: true,
        dictDefaultMessage: translation,
        clickable: `.dropzone-button-${component.dropZoneName}`,
        autoProcessQueue: false,
        headers: {
          Authorization: `Bearer ${this.authService.getToken()}`,
        },
        init: function () {
          const dropzoneInstance = this;
          component.dropzoneInstance = dropzoneInstance;

          this.on('addedfiles', (files: DropzoneFileInterface[] | DropzoneFileListInterface) => {
            component.zone.run(() => {
              const filesArray: DropzoneFileInterface[] = Array.isArray(files) ? files : Array.from(files);

              if (filesArray.length > component.maxNumberOfParallelUploads) {
                component.fileUploadError = FileUploadErrorEnum.MAX_NUMBER_OF_PARALLEL_UPLOADS_EXCEEDED;

                filesArray.forEach((file) => {
                  dropzoneInstance.removeFile(file);
                });
                return;
              }

              const allFilesAccepted = filesArray.every((file) => file.accepted);

              if (!allFilesAccepted) {
                filesArray.forEach((file) => {
                  dropzoneInstance.removeFile(file);
                });
                return;
              }

              filesArray.forEach((file) => component.addTempFile(file));
              dropzoneInstance.processQueue();
            });
          });
          this.on('complete', (file: DropzoneFileInterface) => {
            if (file.status !== 'error') {
              const response = JSON.parse(file.xhr.responseText) as PriceRequestItemFileUploadResponseInterface;
              component.uploadedFilesIds.push(response.data.id);
            }
          });
          this.on('error', (file: DropzoneFileInterface, message: string, xhr: XMLHttpRequest) => {
            component.zone.run(() => {
              component.removeTempFile(file);

              // in case of server-side error
              if (xhr) {
                const response: DropzoneFileUploadErrorResponseInterface = JSON.parse(xhr.responseText);

                const fileErrors =  component.errorService.extractDropzoneFileErrors(response);

                if (component.errorService.includesMimeTypeError(fileErrors)) {
                  component.translator.get('DRAG_DROP.FORMS.ERRORS.UNSUPPORTED_TYPE').subscribe((translation) => {
                    component.toastService.danger(translation);
                  });

                  return;
                }

                if (component.errorService.includesFileSizeError(fileErrors)) {
                  component.translator.get('DRAG_DROP.FORMS.ERRORS.TOO_LARGE').subscribe((translation) => {
                    component.toastService.danger(translation);
                  });

                  return;
                }

                component.translator.get('CREATE_DOCUMENT.TEMPLATE.ATTACH_IMAGE_ERROR').subscribe((translation: string) => {
                  component.toastService.danger(translation);
                });

                return;
              }

              const maxFileSizeInBytes = FileSizeHelper.convertGigabytesToBytes(component.maxFileSizeInGB);

              if (file.size > maxFileSizeInBytes) {
                component.fileUploadError = FileUploadErrorEnum.FILE_TOO_LARGE;
                return;
              }

              component.fileUploadError = FileUploadErrorEnum.UNSUPPORTED_FILE_TYPE;
            });
          });
          this.on('success', () => {
            component.fileUploadError = FileUploadErrorEnum.NONE;
          });
        },
      } as DropzoneConfigInterface;
    });
  }

  writeValue(value: PriceRequestItemFileInterface[]): void {
    this.model = value;
  }

  onChangedCallback = (value: PriceRequestItemFileInterface[]) => { };
  registerOnChange(fn: (value: PriceRequestItemFileInterface[]) => {}): void {
    this.onChangedCallback = fn;
  }

  onTouchedCallback = () => { };
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onUploadSuccess(event) {
    const [request, response, __] = event;
    const { filename } = request.upload;

    const fileIndex = this.model.findIndex(({ file, inProgress }) => inProgress && file.name === filename);
    this.model[fileIndex] = response.data;

    this.onChangedCallback(this.model);
  }

  onRemoveFile(priceRequestItemFile: PriceRequestItemFileInterface) {
    const { id } = priceRequestItemFile;

    if (!this.priceRequestId || this.isDisabled || this.deletedFilesIds.includes(id)) {
      return;
    }

    this.uploadedFilesIds = this.uploadedFilesIds.filter(fileId => fileId !== id);

    this.deletedFilesIds.push(id);

    const fileIndex = this.model.findIndex((item) => item.id === id);

    this.priceRequestItemsService.deleteFile(this.priceRequestId, this.priceRequestItemId, id).subscribe({
      next: () => {
        this.model.splice(fileIndex, 1);
        this.onChangedCallback(this.model);
        this.fileDelete.emit(id);
        this.deletedFilesIds.splice(this.deletedFilesIds.indexOf(id), 1);
      },
      error: () => {
        this.deletedFilesIds.splice(this.deletedFilesIds.indexOf(id), 1);
      }
    });
  }

  addTempFile(file: DropzoneFileInterface) {
    // add temporary file to the list with status "inProgress"
    const newFile: PriceRequestItemFileInterface = {
      id: null,
      type: this.fileType,
      file: {
        id: null,
        name: file.name,
        filename: file.name,
        url: '',
        extension: file.name.split('.').pop(),
      },
      inProgress: true,
    };

    this.model.push(newFile);
    this.onChangedCallback(this.model);
  }

  removeTempFile(completedFile: DropzoneFileInterface) {
    const fileIndex = this.model.findIndex(({ file, inProgress }) => inProgress && file.name === completedFile.name);

    if (fileIndex >= 0) {
      this.model.splice(fileIndex, 1);
    }

    this.onChangedCallback(this.model);
  }

  trackById(index: number, item: PriceRequestItemFileInterface): PriceRequestItemFileInterface['id'] {
    return item.id;
  }

  onDraggingOverDropzoneStart(): void {
    this.draggingOverDropzone = true;
  }

  onDraggingOverDropzoneEnd(): void {
    this.draggingOverDropzone = false;
  }

  resetError(): void {
    this.fileUploadError = FileUploadErrorEnum.NONE;
  }
}
