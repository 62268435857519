import { Injectable } from '@angular/core';
import { ApiService, FileSizeEnum } from '../api.service';
import { CommunicationMessageInterface, MessagesInterface } from '../core/models/messages.model';
import { CacheableObservable } from '../cacheable-observable/cacheable-observable.model';

export interface MessagesListResponseInterface {
  data: CommunicationMessageInterface[];
}

interface MessagesResponseInterface {
  data: CommunicationMessageInterface;
}

@Injectable({
  providedIn: 'root'
})
export class ClaimsMessagesService {

  constructor(
    private api: ApiService
  ) { }

  getMessages(claimId: number): CacheableObservable<MessagesListResponseInterface> {
    return this.api.get(`claims/${claimId}/messages/`);
  }

  sendMessage(claimId: number, { text, files }: Partial<MessagesInterface>): CacheableObservable<MessagesResponseInterface> {
    return this.api.upload(`claims/${claimId}/messages/`, { text }, files, FileSizeEnum.SIZE_1GB) as CacheableObservable<MessagesResponseInterface>;
  }
}
